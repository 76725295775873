<template>
  <v-card flat tile>
    <v-card-title class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
      Profile Overview
    </v-card-title>

    <v-divider />

    <v-card-text>
      <table style="width: 100%;">
        <!-- Display number of completed exercises -->
        <tr>
          <td>
            <b>Exercises Completed:</b>
          </td>
          <td>
            <span v-if="data.exercisesCompleted && data.exercisesCompleted.total">
              {{ data.exercisesCompleted.total }}
            </span>
            <span v-else>
              0
            </span> out of {{ exercises.length }}
          </td>
        </tr>

        <!-- Display the user's last login date -->
        <tr>
          <td>
            <b>Last Login:</b>
          </td>
          <td>
            {{ data['last-active-date'] || 'Error' }}
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
// Mixins
import platform from '@/mixins/platform.js'

// view
export default {
  mixins: [platform],
  props: ['data'],

  data () {
    return {
      star_switch: false
    }
  }
}
</script>

<style>
  .grading-switch,
  .grading-switch .v-input__slot {
    margin: 0;
    padding: 0;
  }

  .grading-switch .v-messages {
    display: none;
  }

  .portfolio-url .v-icon {
    font-size: 14px !important;
  }
</style>
