import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import moment from 'moment-timezone'
import VueGoogleCharts from 'vue-google-charts'
import VueMoment from 'vue-moment'

// Firebase
import firebase from 'firebase'
import { firestorePlugin } from 'vuefire'

// custom components
import ApplicationLayout from '@/layouts/ApplicationLayout.vue'
import PlainLayout from '@/layouts/PlainLayout.vue'

// Application layouts
Vue.component('application-layout', ApplicationLayout)
Vue.component('plain-layout', PlainLayout)

//
Vue.use(firestorePlugin)

// Google Charts
Vue.use(VueGoogleCharts)

// integrate moment.js
Vue.use(VueMoment, {moment})

// Firebase configuration 
const firebaseConfig = {
  apiKey: "AIzaSyDCSJzigNuhm_XIwNOj-mO80XfPOXXxGCw",
  authDomain: "cyber-platform-prod.firebaseapp.com",
  databaseURL: "https://cyber-platform-prod.firebaseio.com",
  projectId: "cyber-platform-prod",
  storageBucket: "cyber-platform-prod.appspot.com",
  messagingSenderId: "353461446805"
}

// Initialise Firebase
firebase.initializeApp(firebaseConfig)

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
