<template>
  <v-container>
    <!-- Loarding Bar -->
    <v-progress-linear v-if="profileScores == null" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Card -->
    <v-card v-else>
      <!-- Toolbar -->
      <v-toolbar dark dense color="green">
        <v-list-item-icon>
          <v-icon>
            mdi-account-details
          </v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          {{ getEmailAddress(userId) }}
        </v-toolbar-title>

        <v-spacer />

        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" dark
              color="green darken-4"
              flat
              small
              >
              Courses &amp; Licenses
            </v-btn>
          </template>

          <Courses :profileSettings="profileSettings" :userId="userId" />
        </v-dialog>
      </v-toolbar>

      <!-- Continued Professional Development -->
      <CPD :data="profileScores"/>

      <!-- Profile Overview -->
      <ProfileOverviewCard :data="profileScores" />

      <!-- Skill Range -->
      <SkillRange :data="[profileScores]" :dashboard="false" />

      <!-- Competencies -->
      <v-card flat tile>
        <v-card-title class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
          Competencies
        </v-card-title>

        <v-divider />
        <TeamMemberCompetencies :data="profileScores" />
      </v-card>

      <!-- Submissions -->
      <v-card flat tile>
        <v-card-title class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
          Submissions
        </v-card-title>

        <v-divider />
        
        <v-tabs v-model="submissionsTab" color="green" slider-color="green" grow show-arrows>
          <v-tab href="#passed-submissions">
            Passed ({{ passedSubmissions.length }})
          </v-tab>

          <v-tab href="#failed-submissions">
            Failed ({{ failedSubmissions.length }})
          </v-tab>

          <v-tab href="#awaiting-submissions">
            Awaiting Review ({{ awaitingReviewSubmissions.length }})
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="submissionsTab">
          <v-tab-item value="passed-submissions" v-if="submissionsTab === 'passed-submissions'">
            <Submissions :data="passedSubmissions" />
          </v-tab-item>

          <v-tab-item value="failed-submissions" v-if="submissionsTab === 'failed-submissions'">
            <Submissions :data="failedSubmissions" />
          </v-tab-item>

          <v-tab-item value="awaiting-submissions" v-if="submissionsTab === 'awaiting-submissions'">
            <Submissions :data="awaitingReviewSubmissions" />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
// firebase
import '@firebase/firestore'
import firebase from 'firebase/app'

// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import user from '@/stores/user.js'
import utils from '@/mixins/utils.js'

// components
import Courses from '@/components/profile/Courses'
import CPD from '@/components/profile/CPD'
import ProfileOverviewCard from '@/components/profile/ProfileOverviewCard'
import SkillRange from '@/components/cards/analytics/SkillRange'
import Submissions from '@/components/Submissions'
import TeamMemberCompetencies from '@/components/cards/TeamMemberCompetencies'

// view
export default {
  mixins: [
    firestore,
    organisation,
    platform,
    user,
    utils
    ],

  data () {
    return {
      dialog: null,
      failedSubmissionsData: [],
      profileScores: null,
      profileSettings: null,
      submissionsTab: null
    }
  },

  computed: {
    /*
     * Retrieve the submissions that are awaiting review
     */
    awaitingReviewSubmissions () {
      return this.items.filter(i => i.pass === null || i.pass === false)
    },

    /*
     * Retrieve the failed submissions
     */
    failedSubmissions () {
      var res = Object.assign([], this.failedSubmissionsData)
        .filter(e => {
          if (e.student == this.userId) {
            var exercise = this.getExercise(e.exercise)
            var title = (exercise) ? exercise.title : 'unknown'
  
            return Object.assign(e, {
              ...e,
              email: this.getEmailAddress(e.student).split('@')[0],
              pass: false,
              title: title,
              uid: e.student
            })
          }
        })
      
      return res
    },

    /*
     * Get the user's ID from the route parameter
     */
    userId () {
      return this.$route.params.id
    },

    /*
     * Get the list of submissions for current user
     */
    items () {
      var res = Object.assign([], this.submissions)
        .filter(e => {
          if (e.student == this.userId) {
            var exercise = this.getExercise(e.exercise)
            var title = (exercise) ? exercise.title : 'unknown'
  
            return Object.assign(e, {
              ...e,
              email: this.getEmailAddress(e.student).split('@')[0],
              title: title,
              uid: e.student
            })
          }
        })
      
      return res
    },

    /*
     * Retrieve the submissions that were passed
     */
    passedSubmissions () {
      return this.items.filter(i => i.pass === true)
    }
  },

  /*
   * Retrieve the data from firestore
   */
  async mounted () {
    // get the current user's score document
    this.profileScores = await this.fsGet('scores', this.userId)

    // get the user's settings
    this.profileSettings = await this.fsGet('settings', this.userId)
  },

  /*
   *
   */
  firestore () {
    return {
      failedSubmissionsData: firebase.firestore()
        .collection('failed-exercises')
        .where('organisation', '==', this.orgID)
        .where('student', '==', this.userId)
    }
  },

  components: {
    Courses,
    CPD,
    ProfileOverviewCard,
    SkillRange,
    Submissions,
    TeamMemberCompetencies
  }
}
</script>

<style>

</style>