<template>
  <v-container>
    <v-layout>
      <v-card
        flat
        style="margin: 10px auto;"
        tile
        width="1200px"
        >
        <!-- Toolbar -->
        <v-toolbar flat dense dark color="#198f3d">
          <v-list-item-icon>
            <v-icon>
              mdi-map-marker-path
            </v-icon>
          </v-list-item-icon>

          <v-toolbar-title>
            Create Pathway
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- Clear Button -->
          <v-btn v-if="pathwayLabel || (pathwaySections.length > 0)"
            color="#198f3d"
            depressed
            @click="clearPathway()"
            >

            Clear
          </v-btn>

          <!-- Save Button - Only visible if there is at least one section created -->
          <v-btn v-if="pathwaySections[0]"
            @click="handleSave()"
            color="#198f3d"
            depressed
            >

            Save
          </v-btn>
        </v-toolbar>

        <!-- Title & Description Card -->
        <v-alert
          icon="mdi-folder-plus-outline"
          outlined
          prominent
          rounded="0"
          text
          type="success"
          >
          Enter a title:

          <v-text-field v-model="pathwayLabel"
            color="green"
            label="Title"
            single-line
          ></v-text-field>
        </v-alert>

        <!-- Title and Description Card -->
        <v-card v-if="pathwayLabel"
          class="mx-auto"
          outlined
          >
          <v-list-item three-line >
            <v-list-item-content>
              <div class="overline mb-4 green--text">
                Pathway
              </div>

              <v-list-item-title class="headline mb-1">
                {{ pathwayLabel }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <!-- Sections -->
        <v-card v-if="pathwayLabel || (pathwaySections.length > 0)"
          class="mx-auto"
          outlined
          >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4 green--text">Sections</div>

              <div v-if="pathwaySections.length > 0" style="margin-bottom:10px;">
                <v-list-item-title class="headline mb-1">
                  Sections
                </v-list-item-title>

                <v-list-item-subtitle>
                  Create sections in the intended order of progression
                </v-list-item-subtitle>
              </div>

              <!-- Section List -->
              <v-expansion-panels style="margin-top:20px;">
                <v-expansion-panel
                  v-model="pathwaySections"
                  v-for="(item,i) in pathwaySections"
                  :key="i"
                  >

                  <v-expansion-panel-header class="bold capitalize text--green">
                    {{ item.title }}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-divider v-if="$vuetify.breakpoint.mdAndDown"></v-divider>

                    <!-- Section's Exercises -->
                    <v-list>
                      <v-list-item-subtitle v-for="(uuid, i) in item.exercises" :key="i">
                        <div style="margin-left:30px; padding:4px;">
                          <v-icon color="green" dense>
                            mdi-checkbox-blank-circle
                          </v-icon>

                          {{ getExerciseTitle(uuid) }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list>

                    <!-- Remove Section Button -->
                    <v-btn 
                      @click="handleRemove(item)"
                      color="green"
                      depressed 
                      outlined 
                      style="float:right;"
                      >

                      Remove Section
                      </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Add Section Button -->
              <v-btn
                @click="toggleSectionDialog"
                color="green"
                dark
                depressed
                style="margin-top:10px;"
                >

                Add Section
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <!-- Create Section Dialog -->
        <CreateSectionDialog
          :createSection="createSection"
          :exercises="exercises"
          :open="sectionDialog"
          :toggleDialog="toggleSectionDialog"
        ></CreateSectionDialog>

        <!-- Save pathway progress box -->
        <ProgressDialog :loading="loading" text="Saving Pathway"/>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// components
import CreateSectionDialog from '@/components/ui/dialogs/CreateSectionDialog'
import ProgressDialog from '@/components/ui/dialogs/ProgressDialog'

export default {
  mixins: [
    firestore,
    organisation,
    platform,
    ui,
    user
    ],

  data () {
    return {
      pathwayLabel: null,
      pathwaySections: [],
      dialog: false,
      sectionDialog: false
    }
  },

  methods: {
    /*
     * Clear a pathway and start again
     */
    clearPathway () {
      this.pathwayLabel = null
      this.pathwaySections = []
    },

    /*
     * Create a new training module in the pathway
     */
    createSection (section) {
      this.sectionDialog = !this.sectionDialog
      this.pathwaySections.push(section)
    },

    /*
     * Remove a training module from the pathway
     */
    handleRemove (item) {
      var sections = this.pathwaySections.filter(section => {
        if (section !== item) {
          return item
        }
      })

      this.pathwaySections = sections
    },

    /*
     * Save pathway to Firestore
     */
    async handleSave (confirm) {
      // prepare payload
      var payload = {
        'curriculum-title': this.pathwayLabel,
        'training-modules': this.pathwaySections,
        orgId: this.orgID,
        users: []
      }

      // save pathway object
      var res = this.fsCreate('custom-pathways', payload)

      // redirect to 'Pathways'
      if (res) {
        this.$router.push('/pathways')
      }
    },

    /*
     * Show a new section dialog
     */
    toggleSectionDialog () {
      this.sectionDialog = !this.sectionDialog
    }
  },

  components: {
    CreateSectionDialog,
    ProgressDialog
  }
}
</script>

<style>

</style>