<template>
  <v-dialog
    v-model="loading"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="#198f3d"
      dark
    >
      <v-card-text style="padding:30px 15px; color:white;">
        {{ text }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
          style="margin:20px auto;"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Firebase

// Mixins

// Components

// View
export default {
  props: ['loading','text'],

}
</script>

<style >

</style>