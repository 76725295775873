<template>
  <div>
    <v-container>
      <!-- Licenses -->
      <v-card width="1200px">
        <v-toolbar flat dark dense color="green">
          <v-list-item-icon>
            <v-icon>
              mdi-license
            </v-icon>
          </v-list-item-icon>

          <v-toolbar-title>
            Licenses
          </v-toolbar-title>
        </v-toolbar>

        <!-- Search -->
        <v-card-title>
          <v-text-field v-model="search"
            append-icon="mdi-magnify"
            color="green"
            hide-details
            label="Search"
            single-line
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="licenses"
          :search="search"
          class="elevation-1 green-header"
          hide-default-footer
          sort-by="email"
          >
          <!-- Expires On -->
          <template v-slot:item.expiry="{ item }">
            {{ formatDate(item.expiry.toDate()) }}
          </template>

          <!-- Action -->
          <template v-slot:item.action="{ item }">
            <v-btn
              @click="licenseId = item.id; dialog = true"
              depressed
              x-small
              >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- Dialog -->
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>
            Assign License: {{ licenseId }}
          </v-card-title>

          <v-card-text>
            <v-select v-model="selectedUser"
              :items="settingsDocs"
              color="green"
              item-text="email"
              filled
              return-object
              single-line
            ></v-select>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />

            <v-btn
              @click="assignLicense()"
              :disabled="!selectedUser"
              :loading="loading"
              color="green"
              small
              >

              Assign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'

// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import ui from '@/mixins/ui.js'

// 
export default {
  mixins: [
    firestore,
    organisation,
    ui
  ],

  data () {
    return {
      dialog: false,

      headers: [
        {
          text: 'Email',
          value: 'email',
          sortable: false
        },

        {
          text: 'SKU',
          value: 'sku',
          sortable: false
        },

        {
          text: 'Expires On',
          value: 'expiry',
          sortable: false
        },

        {
          text: 'Action',
          value: 'action',
          sortable: false
        }
      ],

      licenseId: null,
      search: null,
      selectedUser: null
    }
  },

  methods: {
    /*
     * Assign a license to a selected user
     */
    assignLicense: async function () {
      // validation
      if (!this.selectedUser) {
        return this.throwError('No user selected')
      }

      var email = this.selectedUser.email
      var uid = this.selectedUser.id

      // validation
      if (!email || !uid) {
        return this.throwError('An error has occurred. Please contact us.')
      }

      await this.fsUpdate('licenses', this.licenseId, {
        email: email,
        uid: uid
      })
    },

    /* 
     * Formats date for the table
     */
    formatDate (dt) {
      return moment(dt).format('MMM Do YY')
    }
  }
}
</script>

<style>

</style>