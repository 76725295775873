var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","color":"green","hide-details":"","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.submissions.length > 0)?_c('v-data-table',{staticClass:"green-header",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":50,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading-text":"Loading...","no-data-text":"User has not submitted any exercise"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/profile/" + (item.uid))}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),(_vm.unlockedTransitionMemory)?_c('span',[(item.assessment && item.assessment.comments)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.openDialog(item.assessment.comments, item.id)}}},[_vm._v(" mdi-comment-outline ")]):_vm._e(),(item.youtube)?_c('a',{staticClass:"ml-2 no-decoration",attrs:{"href":item.youtube,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-youtube ")])],1):_vm._e(),(item.videoUrl)?_c('a',{staticClass:"ml-2 no-decoration",attrs:{"href":item.videoUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-video-box ")])],1):_vm._e(),(item.filename)?_c('a',{staticClass:"ml-2 no-decoration",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.bucketOpen(item.filename)}}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" mdi-cloud-download ")])],1):_vm._e()],1):_vm._e()]}},{key:"item.pass",fn:function(ref){
var item = ref.item;
return [(item.pass)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):(item.pass === false)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-timer-sand-empty ")])]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.timestamp))+" ")]}}],null,false,967274286)}):_vm._e(),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2 pdb-24",staticStyle:{"margin-bottom":"0"}},[_vm._v(" Instructor Feedback ")]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"bold"},[_vm._v(" Submission Id: ")]),_vm._v(" "+_vm._s(_vm.submissionId)+" ")]),_c('v-textarea',{attrs:{"filled":"","height":"350"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }