<template>
  <v-dialog v-model="open" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card style="overflow:hidden;">
      <!-- Toolbar -->
      <v-toolbar dark color="green">
        <v-btn icon dark @click="toggleDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title>
          Create Pathway Section
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn v-if="title && sectionExercises.length > 0"
            @click="handleSave(title)"
            dark
            text
            >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-row>
        <!-- Exercise Table -->
        <v-col cols="7">
          <ExerciseTable 
            :checkedHandler="addExercise" 
            :uncheckedHandler="removeExercise"
            :sectionExercises="sectionExercises"
            :singleSelect="false"
          />
        </v-col>

        <!-- Section Title and Form -->
        <v-col cols="5" md="4">
          <v-subheader>
            Section name
          </v-subheader>

          <!-- Title -->
          <v-list-item>
            <v-list-item-content>
              <v-text-field v-model="title"
                color="green"
                dense
                label="Name"
                required
                ></v-text-field>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <!-- Exercise Selection -->
          <v-list three-line subheader class="d-flex">
            <v-list-item >
              <v-list-item-content>
                <v-list-item-title>
                  Selected Exercises
                </v-list-item-title>

                <v-list-item-subtitle>
                  Select the exercises that will make up this section
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            
            <!-- Clear Button -->
            <v-btn 
              outlined
              depressed 
              dark
              color="green darken-1" 
              @click="clearSectionExercises"
              style="margin:25px 7px;float:right;"
              width="100"
              >

              clear
            </v-btn>
          </v-list>

          <!-- Exercise List -->
          <v-data-iterator :items="sectionExercises" hide-default-footer>
            <template v-slot:default="props">           
              <div v-for="(item, index) in props.items" :key="index" class="pd-10">
                <!-- Exercise Card -->
                <v-card
                  color="green"
                  dark
                  flat
                  style="margin: 3px auto;"
                  width="500"
                  >
                  <v-card-subtitle class="white--text">
                    {{ item.title }}
                  </v-card-subtitle>
                </v-card>
              </div>
            </template>
          </v-data-iterator>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
// Components
import ExerciseTable from '@/components/ExerciseTable'

//View
export default {
  props: [
    'createSection',
    'exercises',
    'open',
    'toggleDialog'
    ],

  data () {
    return {
      sectionExercises: [],
      title: null
    }
  },

  methods: {
    /*
     * Add a new exercise to the training module
     */
    addExercise (item) {
      this.sectionExercises.push(item)
    },

    /*
     * Save the new training module that has been created
     */
    handleSave (title) {
      // prepare the training module object
      var section = {
        title: title,
        exercises: this.sectionExercises.map(e => e.uuid)
      }

      // call function from the parent container
      this.createSection(section)

      // Reset Exercise Table
      this.clearSectionExercises()
    },

    /*
     * Remove an exercise that had been selected
     */
    removeExercise (uuid) {
      var filteredArray = this.sectionExercises.filter(exercise => {
        if (exercise.uuid !== uuid) {
          return exercise
        }
      })

      this.sectionExercises = filteredArray
    },

    /*
     * Reset the component's variables
     */
    clearSectionExercises () {
      this.sectionExercises = []
      this.title = null
    }
  },

  components: {
    ExerciseTable
  }
}
</script>

<style >

</style>