<template>
  <v-container>
    <v-layout v-if="pathwayData">
      <v-card
        flat
        width="1200px"
        tile
        style="margin: 10px auto;"
        >
        <!-- Toolbar -->
        <v-toolbar flat dense dark color="#198f3d">
          <v-list-item-icon>
            <v-icon>mdi-map-marker-path</v-icon>
          </v-list-item-icon>

          <v-toolbar-title>
            Edit Pathway: <i>{{ label }}</i>
          </v-toolbar-title>

          <v-spacer />
          
          <!-- Clear Button -->
          <v-btn
            @click="clearPathway"
            color="#198f3d"
            depressed
            >

            Clear
          </v-btn>

          <!-- Save Button - Only visible if there is at least one section created -->
          <v-btn
            @click="handleSave()"
            color="#198f3d"
            depressed
            >

            Save
          </v-btn>
        </v-toolbar>

        <!-- Title & Description Card -->
        <v-alert
          icon="mdi-folder-plus-outline"
          prominent
          text
          type="success"
          outlined
          >
          Enter a title:
          <v-row style="padding-left:20px;">
            <v-text-field v-model="title"
              label="Title"
              single-line
            ></v-text-field>
          </v-row>
        </v-alert>

        <!-- Title Card -->
        <v-card v-if="label" class="mx-auto" outlined>
          <v-list-item three-line >
            <v-list-item-content>
              <div class="overline mb-4 green--text">
                Pathway
              </div>

              <v-list-item-title class="headline mb-1">
                {{ label }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-avatar color="green lighten-2" size="80" tile>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>

        <!-- Sections -->
        <v-card class="mx-auto" outlined>
          <v-list-item three-line >
            <v-list-item-content>
              <div class="overline mb-4 green--text">
                Sections
              </div>

              <div v-if="sections.length > 0" style="margin-bottom:10px;">
                <v-list-item-title class="headline mb-1">
                  Sections
                </v-list-item-title>

                <v-list-item-subtitle>
                  Create sections in the intended order of progression
                </v-list-item-subtitle>
              </div>

              <!-- Section List -->
              <v-expansion-panels style="margin-top:20px;">
                <v-expansion-panel v-for="(item, i) in sections" :key="i">
                  <v-expansion-panel-header style="color:green;">
                    {{ item.title }}
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- Section's Exercises -->
                    <v-list>
                      <v-list-item-subtitle v-for="(uuid, i) in item.exercises" :key="i">
                        <div style="margin-left: 30px; padding: 4px;">
                          <v-icon color="green" dense>
                            mdi-checkbox-blank-circle
                          </v-icon>
                          
                          {{ getExerciseTitle(uuid) }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list>

                    <!-- Remove Section Button -->
                    <v-btn @click="handleRemove(item)"
                      color="green"
                      depressed 
                      outlined 
                      style="float:right;"
                      >
                      Remove Section
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Add Section Button -->
              <v-btn @click="toggleSectionDialog()"
                color="green"
                dark
                depressed
                style="margin-top:10px;"
                >
                Add Section
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <!-- Create Section Dialog -->
        <CreateSectionDialog
          :open="sectionDialog"
          :createSection="createSection"
          :toggleDialog="toggleSectionDialog"
          :exercises="exercises"
          />

        <!-- Save changes to pathway progress box -->
        <ProgressDialog :loading="loading" text='Saving changes to Pathway' />
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import '@firebase/firestore'

// Firebase
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'
import utils from '@/mixins/utils.js'

// components
import CreateSectionDialog from '@/components/ui/dialogs/CreateSectionDialog'
import ProgressDialog from '@/components/ui/dialogs/ProgressDialog'

export default {
  mixins: [
    firestore,
    organisation,
    platform,
    ui,
    user,
    utils
    ],

  data () {
    return {
      dialog: false,
      loading: false,
      pathwayData: null,
      sectionDialog: false,
      sections: [],
      title: null,
      users: []
    }
  },

  computed: {
    /*
     * Returns the curriculum's SKU
     */
    sku () {
      return this.$route.params.id
    }
  },

  methods: {
    /*
     *
     */
    clearPathway () {
      this.sections = []
      this.title = null
    },

    /*
     *
     */
    createSection (section) {
      this.sectionDialog = !this.sectionDialog
      this.sections.push(section)
    },

    /*
     * Remove a section from the training module
     */
    handleRemove (item) {
      this.sections = this.sections.filter(section => {
        if (section !== item) {
          return item
        }
      })
    },

    /*
     * Save the pathway in the backend
     */
    async handleSave () {
      // prepare payload
      var payload = {
        'curriculum-title': this.title,
        'training-modules': this.sections,
        orgId: this.orgID,
        users: this.users
      }

      // firestore save
      var res = this.fsSet('custom-pathways', this.sku, payload)

      // Redirect to 'Pathways'
      if (res) {
        this.$router.push('/pathways')
      }
    },

    /*
     *
     */
    toggleDialog () {
      this.dialog = !this.dialog
    },

    /*
     *
     */
    toggleSectionDialog () {
      this.sectionDialog = !this.sectionDialog
    }
  },

  /*
   * Sync the Firestore object with the local object
   */
  watch: {
    pathwayData (pathway) {
      // sync the title
      this.title = pathway['curriculum-title']

      // sync the training modules
      if (Array.isArray(pathway['training-modules'])) {
        this.sections = [...pathway['training-modules']] // safe copy
      }

      // sync the users
      this.users = [...pathway['users']]
    }
  },

  /*
   *
   */
  firestore () {
    return {
      pathwayData: firebase.firestore()
        .collection('custom-pathways')
        .doc(this.sku)
    }
  },

  components: {
    CreateSectionDialog,
    ProgressDialog
  }
}
</script>

<style>

</style>