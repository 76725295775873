<template>
  <v-container fluid>
    <v-layout align-center justify-center>
      <v-flex class="mt-1" xs12 sm8 md4>
        <div class="align-center mb-20">
          <img class="logo-authentication" src="/images/logo.png" />
        </div>

        <v-card class="max-width-400 mx-auto">
          <!-- Card Header -->
          <v-toolbar color="#198f3d" flat>
            <v-toolbar-title class="text-center width-100-pc white--text">
              Teams Management Portal
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <!-- Authentication form -->
            <v-form v-model="form" @keyup.native.enter="signin">
              <v-text-field v-model="email"
                color="green"
                label="email@example.com"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                >
              </v-text-field>

              <v-text-field v-model="password"
                autocomplete="off"
                color="green"
                label="Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                >
              </v-text-field>
            </v-form>
          </v-card-text>

          <!-- Login buttons -->
          <v-card-text class="login-buttons">
            <v-btn
              :loading="loading"
              @click="signin"
              class="block mb-2 width-100-pc"
              color="green"
              dark
              >

              Login
            </v-btn>

            <v-divider class="mb-3 mt-3"/>

            <div class="text-center mb-3">
              SSO Providers:
            </div>

            <v-btn
              @click="signinWithGoogle"
              class="block mb-2 width-100-pc"
              color="orange darken-3 white--text"
              >

              Sign in with Google
            </v-btn>
          </v-card-text>
        </v-card>

        <!-- Create Account / Forgot Password -->
        <div class="links">
          <router-link to="/forgot-password">
            Forgot Password
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'

// mixins
import ui from '@/stores/ui.js'

export default {
  app: 'Authentication',

  data () {
    return {
      email: null,
      form: null,
      loading: false,
      password: null
    }
  },

  methods: {
    /*
     * Authentication using username and password
     */
    async signin () {
      this.loading = true

      if (this.form && this.email && this.password) {
        await firebase.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$router.push('/dashboard')
          })
          .catch(error => {
            ui.commit('triggerErrorSnackbar', error)
          })
      } else {
        ui.commit('triggerErrorSnackbar', 'Email or password missing')
      }

      this.loading = false
    },

    /*
     * Authentication using Google account
     */
    signinWithGoogle () {
      var provider = new firebase.auth.GoogleAuthProvider()

      firebase.auth()
        .signInWithPopup(provider)
        .then(() => {
          this.$router.push('/dashboard')
        })
        .catch(error => {
          ui.commit('triggerErrorSnackbar', error)
        })
    }
  }
}
</script>

<style>
.logo-authentication {
  display: block;
  margin: 0 auto;
  max-width: 400px;
}

.login-buttons > .v-btn {
  display: block;
  width: 49%;
}

.links {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.links a {
  text-decoration: none;
}
</style>
