<template>
  <div>
    <v-combobox v-model="leaderEmails" v-on:change="handleUpdate"
      :items="orgEmails"
      chips
      clearable
      color="green"
      deletable-chips
      hide-selected
      multiple
      persistent-hint
      ref="combo"
      >
      <template v-slot:selection="{ item }">
        <v-chip
          label
          color="green"
          >
          <span class="pr-2">
            {{ item }}
          </span>

          <v-icon
            small
            @click="removeMember(item)"
            >

            mdi-close
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
// Mixins
import organisation from '@/mixins/organisation.js'
import validation from '@/mixins/validation.js'

// View
export default {
  mixins: [organisation, validation],

  props: ['addFunction', 'memberType'],

  data () {
    return {
      leaderEmails: [],
      form: true
    }
  },

  computed: {
    valid () {
      return this.leaderEmails.length > 0
    }
  },

  methods: {
    /*
     *
     */
    handleUpdate () {
      // Remove non-emails
      var filtered = this.leaderEmails.filter(item => {
        // lowercase
        item = item.toLowerCase()

        if (/.+@.+\..+/.test(item) && this.validateEmail(item) == true) {
          return item
        } 
      })

      this.leaderEmails = filtered

      // add to members
      this.addFunction(this.leaderEmails)
    },

    /*
     *
     */
    removeMember (item) {
      var filtered = this.leaderEmails.filter(member => {
        if (member !== item) {
          return item
        }
      })

      this.leaderEmails = filtered
    },

    /*
     *
     */
    resetValidation () {
      this.$refs.combo.resetValidation()
    },

    /*
     *
     */
    validateEmail (email) {
      return this.orgEmails.includes(email) || 'email not found'
    }
  }
}
</script>

<style >

</style>