<template>
  <v-container>
    <v-card v-if="reports"
      color="grey lighten-4"
      flat
      style="margin: 10px auto;"
      tile
      width="1200px"
      >
      <v-toolbar flat dark dense color="#198f3d">
        <v-list-item-icon>
          <v-icon>mdi-file-chart</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>Reports</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- Generate Report Button -->
        <v-btn
          @click="handleGenerate"
          color="green"
          depressed
          >
          Generate
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="reports"
        :items-per-page="10"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1 green-header"
        >
        <template v-slot:item.created="{ item }">
          <v-chip
            color="lighten-3"
            label
            style="margin: 0 5px;"
          >
          <v-icon left style="margin-right:25px;">
            mdi-calendar-clock
          </v-icon>

          {{ item.created.toDate() }}
        </v-chip>
        </template>

        <template v-slot:item.timeframe="{ item }">
          <!-- Reports from current day -->
          <v-chip
            label
            style="margin: 0 5px;color:white;"
            color="red"
            v-if="timeago(item.created.toDate()) === 'Today'"
          >
          <v-icon small left>mdi-star</v-icon>
            {{ timeago(item.created.toDate() ) }}
          </v-chip>

          <!-- Reports older than current day -->
          <v-chip
            label
            style="margin: 0 5px;color:white;"
            color="green"
            v-else
            >

            {{ timeago(item.created.toDate() ) }}
          </v-chip>
        </template>

        <template v-slot:item.download="{ item }">
          <v-avatar color="green lighten-2" size="30">
            <v-icon @click="handleDownload(item.url)" color="black" size="18">mdi-download</v-icon>
          </v-avatar>
        </template>
      </v-data-table>
    </v-card>

    <!-- Progress Bar -->
    <v-progress-linear v-else color="green" :indeterminate="true"></v-progress-linear>

    <!-- Generating progress box -->
    <ProgressDialog :loading="loading" text="Your report is being generated"/>
    
  </v-container>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Mixins
import organisation from '@/mixins/organisation.js'
import ui from '@/stores/ui.js'
import user from '@/mixins/user.js'

// Components
import ProgressDialog from '@/components/ui/dialogs/ProgressDialog'

// Packages
import * as timeago from 'timeago.js'

// View
export default {
  mixins: [organisation,ui,user],

  data () {
    return {
      headers: [
        {
          align: 'left',
          sortable: false,
          text: 'Created',
          value: 'created'
        },

        { align: 'left', value: 'timeframe' },

        {
          align: 'center',
          sortable: false,
          text: 'Download', 
          value: 'download',
          width: '90px'
        }
      ],

      loading: false,
      sortBy: 'created',
      sortDesc: true
    }
  },

  methods: {
    /*
     * Generate a new report
     */
    handleGenerate () {
      var generateReport = firebase.functions().httpsCallable('reportsTeamReports')
      this.loading = true

      generateReport()
        .then(res => {
          this.loading = false
          ui.commit('triggerSuccessSnackbar', 'Team Report Successfully Generated')
        })
        .catch(error => {
          this.loading = false
          ui.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     * Open a new tab to download a report
     */
    handleDownload (url) {
      window.open(url, '_blank')
    },

    /*
     * Generates 'x days ago' strings
     */
    timeago (time) {
      var timestring = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`
      var timeframe = timeago.format(timestring)

      if (timeframe.includes('hours')) {
        return 'Today'
      } else {
        return timeframe
      }
    }
  },

  components: {
    ProgressDialog
  }
}
</script>

<style>

</style>