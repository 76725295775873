import ui from '@/stores/ui.js'

export default {
  computed: {
    /*
     *
     */
    toggleAlert () {
      ui.commit('toggleAlert')
    }
  },

  methods: {
    /*
     * Displays a RED error message
     */
    throwError (error) {
      ui.commit('triggerErrorSnackbar', error)
    },

    /*
     * Displays a GREEN success message
     */
    throwSuccess (message) {
      ui.commit('triggerSuccessSnackbar', message)
    }
  }
}