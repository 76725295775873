<template>
  <v-container>
    {{ users }}
    <!-- Loarding Bar -->
    <v-progress-linear v-if="!domain || !items" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Card -->
    <v-card v-else>
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>
            mdi-chart-scatter-plot
          </v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          Cyber Domain: {{ formatText(domain) }}
        </v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="green-header"
        >
        <template v-slot:item.email="{ item }">
          <router-link :to="`/profile/${item.id}`" style="text-decoration:none;">
            {{ getEmailAddress(item.id) }}
          </router-link>
        </template>

        <template v-slot:item.totalPoints="{ item }">
          {{ item.totalPoints || 0 }}
        </template>

        <template v-slot:item.exercisesCompleted="{ item }">
          {{ item.exercisesCompleted.total || 0 }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import utils from '@/mixins/utils.js'

// view
export default {
  mixins: [organisation, platform, utils],

  data () {
    return {
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Score', value: 'totalPoints'},
        { text: 'Nb. Exercises', value: 'exercisesCompleted'},
        { text: 'Nb. Logins', value: 'active-logins'},
        { text: 'Last Seen', value: 'last-active-date'}
      ],

      sortBy: 'totalPoints',
      sortDesc: true
    }
  },

  computed: {
    /*
     *
     */
    domain () {
      return this.$route.params.domain || null
    },

    /*
     *
     */
    items () {
      return this.scores.filter(doc => {
        if (!doc.exercisesPoints) {
          return false
        }

        if (!doc.exercisesPoints[this.domain]) {
          return false
        }

        return true
      })
    }
  }
}
</script>

<style>

</style>