<template>
  <div>
    <div v-if="loaded">
      <!-- Laptop/Desktop Formatting -->
      <v-card v-if="$vuetify.breakpoint.lgAndUp" class="team-member" width="750" flat>
        <v-toolbar color="green" dark dense>
          <v-toolbar-title style="padding-right: 30px; font-size: 1.1rem;">
            {{ data.email }}

            <!-- Delete Button -->
            <v-btn @click="removeUser(data.email)" class="mx-2" icon depressed x-small>
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-toolbar-title>
        </v-toolbar>

        <v-divider />

        <!-- Progress -->
        <v-expansion-panels flat>
          <v-expansion-panel flat>
            <v-expansion-panel-header>
              Total Points: {{ scores.totalPoints || 0 }} <v-spacer></v-spacer> Progress:
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-card v-for="(score, key, index) in scores.exercisesPoints" :key="index" flat>
                <div>
                  <span style="margin:10px 0px;">
                    <b style="font-size: 13px">
                      {{ formatText(key) }}:
                    </b> 

                    <span style="font-size: 11px"> 
                      {{ score }} 

                        <span v-if="score > 1">
                          pts
                        </span>

                        <span v-else>
                          pt
                        </span>
                    </span>

                  </span>

                  <div>
                    <v-progress-linear
                      color="green"
                      :value="score"
                      height="5"
                      style="margin: 5px 0px;"
                    ></v-progress-linear>
                  </div>
                </div>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>

      <!-- Mobile Formatting -->
      <v-card class="team-member" flat v-else>
        <v-list-item style="background:#66BB6A;" >
          <v-list-item-content>
            <v-list-item-title>
              {{ data.email }}
            </v-list-item-title>
          </v-list-item-content>

        <!-- Delete Button -->
        <v-btn class="mx-2" icon depressed small color="white">
          <v-icon dark>mdi-delete</v-icon>
        </v-btn>

        </v-list-item>
        
        <!-- Progress -->
        <v-expansion-panels flat>
          <v-expansion-panel flat>
            <v-expansion-panel-header>
              Total Points: {{ scores.totalPoints || 0 }} <v-spacer></v-spacer> Progress:
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <TeamMemberCompetencies :data="scores" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
  </div>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Components
import TeamMemberCompetencies from '@/components/cards/TeamMemberCompetencies'

// Mixins
import user from '@/mixins/user.js'
import utils from '@/mixins/utils.js'

export default {
  props: ['removeUser', 'search', 'data'],

  mixins: [user, utils],

  data () {
    return {
      scores: null,
    }
  },

  computed: {
    loaded () {
      return this.scores !== null && this.scores.exercisesPoints && this.data
    }
  },

  firestore () {
    return {
      scores: firebase.firestore()
        .collection('scores')
        .doc(this.data.id)
    }
  },

  components: {
    TeamMemberCompetencies
  }
}
</script>

<style>

</style>
