<template>
  <v-dialog v-model="open" persistent max-width="600px">
    <v-card>
      <v-card-title style="background:green;color:white;">
        <span class="headline">Create New Team</span>
      </v-card-title>
      <v-card-text>
        <v-container>

          <!-- Team Name Text Field -->
          <v-text-field dense label="Title*" required v-model="name"></v-text-field>

          <!-- Member Combo Box -->
          <MemberCombobox :addFunction="setTeamLeaders" memberType="team leaders"/>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>

      <!-- Buttons -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="toggleDialog">Close</v-btn>
        <v-btn color="green darken-1" text @click="handleSave">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Mixins
import organisation from '@/mixins/organisation.js'
import user from '@/mixins/user.js'
import snack from '@/stores/ui.js'

// Components
import MemberCombobox from '@/components/ui/comboboxes/MemberCombobox'

// View
export default {
  mixins:[organisation,user],

  props: ['open','createTeam','toggleDialog'],

  data () {
    return {
      name: null,
      teamLeaders: null
    }
  },

  methods: {
    setTeamLeaders (leaders) {
      this.teamLeaders = leaders
    },

    async handleSave () {
      var team = {
        members: [],
        name: this.name,
        pathways: [],
        'team-leaders': this.teamLeaders
      }
      
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .add(team)
        .then(() => {
          snack.commit('triggerSuccessSnackbar', "Team Successfully Created")
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })

      this.name = null
      this.teamLeaders = null
      this.toggleDialog()
    }
  },

  components: {
    MemberCombobox
  }

}
</script>

<style >

</style>