// Imports

export default {
  methods: {
    removeExerciseHelper (item, array) {
      var filteredArray = array.filter(exercise => {
        exercise === item
      })
      console.log(item)
      array = filteredArray
    }
  }
}