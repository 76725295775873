<template>
  <div>
    <!-- List -->
    <ul v-if="references && references.length > 0" class="green-bullets">
      <li v-for="(reference, index) in references" :key="index">
        <a :href="reference.url" target="_blank">{{ reference.label }}</a> {{ reference.author || '' }} (<a @click="removeReference(reference.url)">remove</a>)
      </li>
    </ul>

    <!-- Add -->
    <v-layout class="mt-5" row style="margin: 0;">
      <!-- Label -->
      <v-flex>
        <v-text-field v-model="label"
          color="green"
          dense
          filled
          label="Label"
          required
          style="margin-right: 5px;"
        ></v-text-field>
      </v-flex>

      <!-- URL -->
      <v-flex>
        <v-text-field v-model="url"
          color="green"
          dense
          filled
          label="URL"
          required
          style="margin-right: 5px;"
        ></v-text-field>
      </v-flex>

      <!-- Author -->
      <v-flex>
        <v-text-field v-model="author"
          append-outer-icon="mdi-plus"
          color="green"
          dense
          filled
          label="Author"
          @click:append-outer="addReference"
        ></v-text-field>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// mixins
import regex from '@/mixins/regex.js'
import ui from '@/mixins/ui.js'

// component
export default {
  props: ['value'],

  mixins: [regex, ui],

  data () {
    return {
      author: '',
      label: '',
      references: [],
      url: ''
    }
  },

  computed: {
    /*
     * Standard VueJS code to allow v-model from the parent
     */
    fieldModelValue: {
      get () {
        return this.value
      },
      
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  watch: {
    /*
     * Emit updates to the parent component
     */
    references (newVal) {
      this.$emit('input', newVal)
    }
  },

  methods: {
    /*
     * Add a reference
     */
    addReference () {
      if (!this.label) {
        return this.throwError('Label field required')
      }

      if (!this.url) {
        return this.throwError('URL field required')
      }

      if (!this.url.startsWith('http://') && !this.url.startsWith('https://')) {
        return this.throwError('URL field is incorrect. Please ensure that it starts with http:// or https://')
      }

      this.references.push({
        author: this.author,
        label: this.label,
        url: this.url
      })

      this.author = ''
      this.label = ''
      this.url = ''
    },

    /*
     * Remove a reference
     */
    removeReference (url) {
      this.references = this.references.filter(r => r.url !== url)
    }
  },

  /*
   * Design pattern to sync the local variable with the value from v-model
   */
  mounted () {
    if (Array.isArray(this.value) && this.value.length > 0) {
      this.references = this.value
    }
  }
}
</script>

<style>

</style>