<template>
  <v-card class="mt-5">
    <v-toolbar flat dark dense color="green">
      <v-list-item-icon>
        <v-icon>mdi-file-find</v-icon>
      </v-list-item-icon>

      <v-toolbar-title>
        Preview
      </v-toolbar-title>
    </v-toolbar>

    <!-- Exercise -->
    <v-card-text v-if="loaded">
      <v-layout row wrap class="exercise" style="margin: 0">
        <!-- Description -->
        <v-flex xs12 sm8>
          <h2>{{ title }}</h2>

          <h3>Exercise</h3>

          <p v-for="(paragraph, index) in split(description)" :key="index">
            {{ paragraph }}
          </p>

          <!-- Learning Objectives -->
          <div v-if="objectives">
            <h3>Learning Objectives</h3>

            <p v-for="(paragraph, index) in split(objectives)" :key="index">
              {{ paragraph }}
            </p>
          </div>

          <!-- Mission Objectives -->
          <div v-if="mission">
            <h3>Mission Objectives</h3>

            <ul class="mb-4 green-bullets">
              <li v-for="(objective, index) in split(mission)" :key="index">
                {{ objective }}
              </li>
            </ul>
          </div>

          <!-- Key Questions -->
          <div v-if="questions">
            <h3>Key Questions</h3>

            <ul class="mb-4 green-bullets">
              <li v-for="(question, index) in split(questions)" :key="index">
                {{ question }}
              </li>
            </ul>
          </div>

          <!-- Specifications -->
          <div v-if="specifications && specifications.length > 0">
            <h3>Specifications</h3>

            <v-checkbox v-for="(spec, index) in specifications" :key="index"
              :label="spec"
              class="specification"
              color="green"
              style="height: unset; margin: 0; padding: 0;"
            ></v-checkbox>
          </div>

          <!-- References -->
          <div v-if="references && references.length > 0">
            <h3>References</h3>

            <ul class="green-bullets">
              <li v-for="(reference, index) in references" :key="index">
                <a :href="reference.url" target="_blank">{{ reference.label }}</a> {{ reference.author || '' }}
              </li>
            </ul>
          </div>
        </v-flex>

        <!-- Overview -->
        <v-flex xs12 sm4>
          <Overview v-if="category || difficulty"
            :category="category"
            :difficulty="difficulty"
          ></Overview>
        </v-flex>
      </v-layout>
    </v-card-text>

    <!-- Error Message -->
    <v-card-text v-else>
      <span style="color: red">
        Please select a category and difficulty level. Then, type a title, description and at least one specification.
      </span>
    </v-card-text>

    <!-- Actions -->
    <v-card-actions>
      <v-btn outlined small to="/exercise-builder/list">
        Cancel
      </v-btn>

      <v-btn v-if="docId"
        @click="del()"
        :loading="loading"
        class="white--text"
        color="red"
        outlined
        small
        >
        Delete
      </v-btn>

      <v-spacer />

      <span v-if="loaded && error" class="fs-12 red--text">
        {{ error }}
      </span>

      <v-spacer />

      <v-btn
        :disabled="!validated"
        :loading="loading"
        @click="save()"
        color="green"
        small
        >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import user from '@/mixins/user.js'

// components
import Overview from '@/components/cards/exercise-builder/Overview'

// component
export default {
  mixins: [firestore, user],

  props: [
    'category',
    'description',
    'difficulty',
    'mission',
    'objectives',
    'questions',
    'references',
    'specifications',
    'title'
  ],

  computed: {
    /*
     *
     */
    docId () {
      return this.$route.params.docId || null
    },

    /*
     *
     */
    error () {
      if (!Array.isArray(this.specifications) || this.specifications.length === 0) {
        return 'Add a specification'
      }

      if (!this.difficulty) {
        return 'Select a difficulty level'
      }

      if (!this.category) {
        return 'Select a category'
      }
    },

    /*
     * Return TRUE if the preview can be displayed
     */
    loaded () {
      return this.title && this.description
    },

    /*
     * Returns TRUE if all the required fields are prepared
     */
    validated () {
      return this.loaded
        && this.category
        && this.difficulty
        && Array.isArray(this.specifications)
        && this.specifications.length > 0
    }
  },

  methods: {
    /*
     * Delete the exercise
     */
    async del () {
      if (await this.fsDelete('exercises', this.docId)) {
        this.$router.push('/exercise-builder/list')
      }
    },

    /*
     * Save the exercise firestore document
     */
    async save () {
      var res = false

      // prepare the document
      var doc = {
        author: this.email,
        category: this.category.uid,
        description: this.description,
        difficulty: this.difficulty,
        organisation: this.orgID,
        mission: this.split(this.mission),
        objectives: this.objectives || null,
        questions: this.split(this.questions),
        references: this.references,
        specifications: this.specifications,
        title: this.title,
        uid: this.uid,
        uuid: this.uuidv4()
      }

      // create or update
      if (!this.docId) {
        res = await this.fsCreate('exercises', doc)
      } else {
        res = await this.fsUpdate('exercises', this.docId, doc)
      }

      if (res) {
        this.$router.push('/exercise-builder/list')
      }
    },

    /*
     * Split lines from text and returns an array
     */
    split (text) {
      if (!text) {
        return null
      }

      var lines = text.split("\n")

      if (lines.length === 1 && lines[0] === '') {
        return null
      }

      return lines
    },

    /*
    * Generate a UUID version 4
    * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    */
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
  },

  components: {
    Overview
  }
}
</script>

<style>
.exercise h2 {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.exercise h3 {
  margin-bottom: 20px;
}

.specification .v-label {
  font-size: 0.875rem;
}
</style>