<template>
  <v-container>
    <v-card width="1200px">
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>mdi-book-edit</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          Custom Exercises
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn outlined color="white" to="/exercise-builder/new">
          Create
        </v-btn>
      </v-toolbar>

      <v-card-title>
        <v-text-field v-model="search"
          append-icon="mdi-magnify"
          color="green"
          hide-details
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="100"
        :search="search"
        class="green-header"
        >
        <template v-slot:item.title="{ item }">
          <router-link :to="`/exercise-builder/edit/${item.id}`" class="no-decoration">
            {{ item.title }}
          </router-link>
        </template>

        <template v-slot:item.category="{ item }">
          {{ formatText(item.category) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase'

// mixins
import user from '@/mixins/user.js'
import utils from '@/mixins/utils.js'

// view
export default {
  mixins: [user, utils],

  data () {
    return {
      headers: [
        { text: 'Title', value: 'title' },
        { text: 'Category', value: 'category', class: 'category hidden-xs-only' },
        { text: 'Difficulty', value: 'difficulty', class: 'difficulty hidden-xs-only' }
      ],

      items: null,
      search: null
    }
  },

  /*
   * Firestore connector
   */
  firestore () {
    return {
      items: firebase.firestore()
        .collection('exercises')
        .where('organisation', '==', this.orgID)
    }
  }
}
</script>

<style>
.category {
  width: 200px;
}

.difficulty {
  width: 160px;
}
</style>