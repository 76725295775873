<template>           
  <v-card class="manager-card" flat>
    <v-toolbar color="green lighten-1" flat dense>
      
      <v-toolbar-title>{{ data.name }}</v-toolbar-title>
    </v-toolbar>

    <v-divider />

    <!-- Details -->
    <v-card-text class="fs-16">
      <div class="mb-5">
        <v-icon class="mr-2" small>mdi-email</v-icon> {{ data.id }} 
      </div>

      <v-icon class="mr-2" small>mdi-phone</v-icon> {{ data.phone }}
    </v-card-text>

  </v-card>
</template>

<script>

export default {
  props: ['search', 'data'],

}
</script>

<style>

</style>