<template>
  <v-main>
    <slot/>
  </v-main>
</template>

<script>
export default {

}
</script>

<style>

</style>
