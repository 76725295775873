
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import user from '@/stores/user.js'
import Vuex from 'vuex'
import Vue from 'vue'
import firebase from 'firebase/app'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    licenses: null,
    orgDoc: null,
    orgID: null,
    pathways: null,
    reports: null,
    scores: null,
    settingsDocs: [],
    submissions: null,
    teams: []
  },

  getters: {
    //
    getLicenses (state) {
      return state.licenses
    },

    //
    getOrgDoc (state) {
      return state.orgDoc
    },
    
    //
    getOrgTeams (state) {
      return state.teams
    },

    //
    getPathways (state) {
      return state.pathways
    },

    //
    getReports (state) {
      return state.reports
    },

    //
    getScores (state) {
      return state.scores
    },

    //
    getSettingsDocs (state) {
      return state.settingsDocs
    },

    //
    getSubmissions (state) {
      return state.submissions
    },

    //
    getOrgID (state) {
      return state.organisation
    }
  },

  actions: {
    /*
     * Retrieve the `licenses` documents
     */
    bindLicenses: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('licenses', firebase
          .firestore()
          .collection('licenses')
          .where('orgId', '==', state.orgID)
        )
      }
    }),

    /*
     * Retrieve the `organisation` document
     */
    bindOrgDoc: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('orgDoc', firebase
          .firestore()
          .collection('organisations')
          .doc(state.orgID)
        )
      }
    }),
      
    /*
     * Retrieve the `pathways` documents
     */
    bindPathways: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('pathways', firebase
          .firestore()
          .collection('custom-pathways')
          .where('orgId', '==', state.orgID)
        )
      }
    }),

    /*
     * Retrieve the `reports` documents
     */
    bindReports: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('reports', firebase.firestore()
          .collection('organisations')
          .doc(state.orgID)
          .collection('reports')
        )
      }
    }),

    /*
     * Retrieve the `scores` documents
     */
    bindScores: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('scores', firebase.firestore()
          .collection('scores')
          .where('organisation', '==', state.orgID)
        )
      }
    }),

    /*
     * Retrieve the settings documents of all the users in an organization
     */
    bindSettingsDocs: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('settingsDocs', firebase
          .firestore()
          .collection('settings')
          .where('organisation', '==', state.orgID)
        )
      }
    }),

    /*
     * Retrieve the submissions documents of all the users in an organization
     */
    bindSubmissions: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('submissions', firebase
          .firestore()
          .collection('submissions')
          .where('organisation', '==', state.orgID)
        )
      }
    }),

    /*
     * Retrieve all the teams that belong to an organization
     */
    bindTeams: firestoreAction(({ state, bindFirestoreRef }) => {
      if (state.orgID) {
        return bindFirestoreRef('teams', firebase
          .firestore()
          .collection('organisations')
          .doc(state.orgID)
          .collection('teams')
        )
      }
    })
  },

  mutations: {
    /*
     *
     */
    async setOrgID (state) {
      var settings = user.getters.getSettings

      state.orgID = settings.organisation
    },

    ...vuexfireMutations
  }

})
