<template>
  <v-alert
    :value="alert"
    type="error"
    transition="scale-transition"
    icon="mdi-information"
    border="left"
    :style="alertStyle"
  >
    <h3 class="headline">Remove Team</h3>
    <div>Are you sure you would like to remove <span style="text-decoration: underline;">{{ title }}</span>?</div>
    <v-divider
      class="my-4 "
      color="white"
      style="opacity: 0.22"
    ></v-divider>

    <v-row
      align="center"
      no-gutters
    >
      <v-col class="grow">
        <v-btn
          color="white"
          outlined
          @click="handleRemove"
          style="width:100px;margin:5px;"
        >
          Remove
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-btn
          color="white"
          outlined
          @click="toggleAlert"
          style="width:100px;margin:5px;"
        >
          Cancel
        </v-btn>
        
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import ui from '@/stores/ui.js'

export default {
  props: ['title'],

  methods: {

    handleRemove: function () {
      this.$emit('confirm', 'yes');
      ui.commit('toggleAlert')
    },

    toggleAlert () {
      ui.commit('toggleAlert')
    }
  },

  computed: {
    alert () {
      return ui.getters.alert
    },

    alertStyle () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'position:fixed;top:40%;right:0;left:0;z-index:90;' // Phone
          case 'sm': return 'position:fixed;right:28%;top:35%;z-index:90;' // Tablet
          case 'md': return 'position:fixed;right:37%;top:35%;z-index:90;' // Laptop
          case 'lg': return 'position:fixed;right:28%;top:35%;z-index:90;' // Desktop
          case 'xl': return 'position:fixed;right:35%;top:35%;z-index:90;'
        }
    }
  },
}

</script>

<style>
</style>