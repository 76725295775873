<template>
  <div>
    <!-- Submissions -->
    <v-card-title>
      <v-text-field v-model="search"
        append-icon="mdi-magnify"
        color="green"
        hide-details
        label="Search"
        single-line
      ></v-text-field>
    </v-card-title>

    <v-data-table v-if="submissions.length > 0"
      :headers="headers"
      :items="data"
      :items-per-page="50"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="green-header"
      loading-text="Loading..."
      no-data-text="User has not submitted any exercise"
      >
      <!-- Email -->
      <template v-slot:item.email="{ item }">
        <router-link :to="`/profile/${item.uid}`" style="text-decoration:none;">
          {{ item.email }}
        </router-link>
      </template>

      <!-- Exercise Title -->
      <template v-slot:item.title="{ item }">
        {{ item.title }}

        <!-- Super users / administrators can download the submission -->
        <span v-if="unlockedTransitionMemory">

          <!-- Instructor Feedback -->
          <v-icon v-if="item.assessment && item.assessment.comments"
            @click="openDialog(item.assessment.comments, item.id)"
            color="green"
            small
            >

            mdi-comment-outline
          </v-icon>

          <!-- YouTube -->
          <a v-if="item.youtube" :href="item.youtube" class="ml-2 no-decoration" target="_blank">
            <v-icon color="green" small>
              mdi-youtube
            </v-icon>
          </a>

          <!-- Video -->
          <a v-if="item.videoUrl" :href="item.videoUrl" class="ml-2 no-decoration" target="_blank">
            <v-icon color="green" small>
              mdi-video-box
            </v-icon>
          </a>

          <!-- Filename -->
          <a v-if="item.filename" @click="bucketOpen(item.filename)" class="ml-2 no-decoration" target="_blank">
            <v-icon color="green" small>
              mdi-cloud-download
            </v-icon>
          </a>
        </span>
      </template>

      <!-- Pass -->
      <template v-slot:item.pass="{ item }">
        <v-icon v-if="item.pass" color="green">
          mdi-check
        </v-icon>

        <v-icon v-else-if="item.pass === false" color="red">
          mdi-close
        </v-icon>
        
        <v-icon v-else color="orange">
          mdi-timer-sand-empty
        </v-icon>
      </template>

      <!-- Timestamp -->
      <template v-slot:item.timestamp="{ item }">
        {{ formatDate(item.timestamp) }}
      </template>
    </v-data-table>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="headline grey lighten-2 pdb-24" style="margin-bottom:0;">
          Instructor Feedback
        </v-card-title>

        <v-divider />

        <v-card-text>
          <div class="mb-2">
            <span class="bold">
              Submission Id:
            </span> 

            {{ submissionId }}
          </div>

          <v-textarea v-model="comments" filled height="350"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn @click="dialog = false" depressed>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'
import permissions from '@/mixins/permissions.js'
import platform from '@/mixins/platform.js'
import user from '@/stores/user.js'

// view
export default {
  props: ['data'],

  mixins: [
    organisation,
    permissions,
    platform,
    user
  ],

  data () {
    return {
      comments: null,
      dialog: false,

      headers: [
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Exercise', value: 'title', sortable: false },
        { text: 'Status', value: 'pass', sortable: false },
        { text: 'Timestamp', value: 'timestamp', sortable: false }
      ],

      search: null,
      sortBy: 'timestamp',
      sortDesc: true,
      submissionId: null
    }
  },

  methods: {
    /*
     * Format the date
     */
    formatDate (timestamp) {
      var dt = new Date(timestamp.toDate())

      return dt.toISOString().split('T')[0]
    },

    /*
     * Open the dialog comments
     */
    openDialog (comments, submissionId) {
      this.comments = comments
      this.dialog = true
      this.submissionId = submissionId
    }
  }
}
</script>
