<template>
  <v-container>
    <v-layout v-if="team && members">
      <v-card
        color="grey lighten-4"
        flat
        height="200px"
        width="1200px"
        tile
        style="margin: 10px auto;"
        >
        <v-toolbar flat dark color="#198f3d">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-toolbar-title>{{ team.name }}</v-toolbar-title>

          <!-- Text Field for renaming the current Team - Only visible in edit mode -->
          <v-text-field
            v-if="edit"
            v-model="teamName"
            dense
            style="margin-left:20px;max-width:250px;"
          ></v-text-field>

          <!-- Save Button - Only visible if in edit mode -->
          <v-btn v-if="edit" text icon @click="renameTeam">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <!-- Edit Button -->
          <v-btn text icon @click="toggleEdit">
            <v-icon v-if="!edit">mdi-pencil</v-icon>
            <v-icon v-else>mdi-cancel</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <!-- Remove Button - only visible if current user is 'Super' -->
          <v-btn
            color="#198f3d"
            depressed
            @click="removeTeam"
            v-if="isAdmin"
            >
            Remove
          </v-btn>
        </v-toolbar>

        <!-- Tab Headers -->
        <v-tabs color="green" slider-color="green" grow show-arrows>
          <v-tab>
            <v-icon left>mdi-information-outline</v-icon> Team Summary
          </v-tab>

          <v-tab>
            <v-icon left>mdi-account-plus</v-icon> Add Team Members
          </v-tab>

          <!-- Tab 1: Team Summary -->
          <v-tab-item>
            <TeamSummary :team="team" :members="members" />
          </v-tab-item>

          <!-- Tab 2: Add New Team Member -->
          <v-tab-item>
            <AddMember />
          </v-tab-item>
        </v-tabs>

        <!-- Remove Team Alert -->
        <RemoveAlert :title="team.name || null" v-on:confirm="handleRemove($event)"/>
        
      </v-card>
    </v-layout>

    <!-- Progress Bar -->
    <v-progress-linear v-else color="green" indeterminate></v-progress-linear>
  </v-container>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Mixins
import organisation from '@/mixins/organisation.js'
import ui from '@/mixins/ui.js'
import snack from '@/stores/ui.js'
import user from '@/mixins/user.js'

// Components
import AddMember from '@/components/tabs/teamview/AddMember'
import RemoveAlert from '@/components/ui/alerts/RemoveAlert'
import TeamSummary from '@/components/tabs/teamview/TeamSummary'

// View
export default {
  mixins: [organisation, ui, user],

  data () {
    return {
      edit: false,
      search: '',
      teamName: null
    }
  },

  computed: {
    // Get team
    team () {
      return this.orgTeams.find(obj => obj.id === this.$route.params.id)
    },

    // Get members
    members () {
      return this.team.members || null
    }
  },

  methods: {
    /*
     * Rename Team
     */
    async renameTeam () {
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .doc(this.$route.params.id)
        .update({
          name: this.teamName
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', "Team Name Updated")
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })

      this.edit = !this.edit
    },

    /*
     *
     */
    removeTeam () {
      this.toggleAlert
    },

    /*
     * Delete team
     */
    async handleRemove (confirm) {
      if (confirm) {
        await firebase.firestore()
          .collection('organisations')
          .doc(this.orgID)
          .collection('teams')
          .doc(this.team.id)
          .delete()
          .then(() => {
            snack.commit('triggerSuccessSnackbar', "Team Successfully Removed")
          })
          .catch(error => {
            snack.commit('triggerErrorSnackbar', error)
          })

      }

      this.$router.push('/teams')
    },

    /*
     *
     */
    toggleEdit () {
      this.edit = !this.edit
    }
  },

  components: {
    AddMember,
    RemoveAlert,
    TeamSummary
  }
}
</script>

<style>

</style>