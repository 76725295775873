<template> 
  <div style="padding:10px;">
    <v-card v-for="(score, key, index) in data.exercisesPoints" :key="index" flat>
      <span style="margin:10px 0px;">
        <b style="font-size: 13px">
          {{ formatText(key) }}:
        </b> 

        <span style="font-size: 11px">
          {{ score }} 

            <span v-if="score > 1">
              pts
            </span>

            <span v-else>
              pt
            </span>
        </span>
      </span>

      <div>
        <v-progress-linear
          color="green"
          :value="score"
          height="5"
          style="margin: 5px 0px;"
        ></v-progress-linear>
      </div>
    </v-card>
  </div>          
</template>

<script>
// Mixins
import utils from '@/mixins/utils.js'

// View
export default {
  mixins: [utils],
  props: ['data'],

}
</script>

<style>

</style>