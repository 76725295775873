import firebase from 'firebase'

// mixins
import ui from '@/mixins/ui.js'

// mixin
export default {
  mixins: [ui],

  data () {
    return {
      db: firebase.firestore(),
      loading: false
    }
  },

  methods: {
    /*
     * Create
     */
    async fsCreate (collection, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .add(doc)
        .then((docRef) => {
          this.throwSuccess('Created!')

          return docRef
        })
        .catch((error) => {
          window.console.error(error)

          this.throwError(error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Delete
     */
    async fsDelete (collection, docId) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .delete()
        .then(() => {
          this.throwSuccess('Deleted!')

          return true
        })
        .catch((error) => {
          window.console.error(error)

          this.throwError(error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Get
     */
    async fsGet (collection, docId, ignoreErrors = false) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            if (!ignoreErrors) {
              this.throwSuccess('The document requested does not exist')
            }

            return null
          }

          return doc.data()
        })
        .catch((error) => {
          window.console.error(error)

          if (!ignoreErrors) {
            this.throwError(error)
          }

          return null
        })
      
      this.loading = false

      return res
    },

    /*
     * Set
     */
    async fsSet (collection, docId, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .set(doc)
        .then(() => {
          this.throwSuccess('Created!')

          return true
        })
        .catch((error) => {
          window.console.error(error)

          this.throwError(error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Set (merge)
     */
    async fsSetMerge (collection, docId, doc, message = 'Updated!') {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .set(doc, { merge: true })
        .then(() => {
          this.throwSuccess(message)

          return true
        })
        .catch((error) => {
          window.console.error(error)

          this.throwError(error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Update
     */
    async fsUpdate (collection, docId, doc) {
      this.loading = true

      var res = await this.db
        .collection(collection)
        .doc(docId)
        .update(doc)
        .then(() => {
          this.throwSuccess('Updated!')

          return true
        })
        .catch((error) => {
          window.console.error(error)

          this.throwError(error)

          return false
        })

      this.loading = false

      return res
    },

    /*
     * Prepares a new firestore object for updating/creating
     */
    newFsObject (original, newValues) {
      var doc = {}

      Object.keys(original).forEach(k => {
        if (Object.keys(newValues).includes(k)) {
          doc[k] = newValues[k]
        }
      })

      return doc
    },

    /*
     * Safely copies the keys from one object into another object
     * only if the key of the first object has the key of the second
     * object 
     */
    safeCopyObjects (from, to) {
      Object.keys(from).forEach(k => {
        if (Object.keys(to).includes(k)) {
          to[k] = from[k]
        }
      })
    }
  }
}
