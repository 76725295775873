import Vue from 'vue'
import VueRouter from 'vue-router'

import Administrators from '@/views/organization/Administrators'
import Authentication from '@/views/Authentication'
import CapabilitiesOverview from '@/views/capabilities/Overview'
import Capability from '@/views/capabilities/Capability'
import CreatePathway from '@/views/pathways/CreatePathway'
import CustomExercises from '@/views/exercise-builder/List'
import Dashboard from '@/views/Dashboard'
import EditPathway from '@/views/pathways/EditPathway'
import ExerciseBuilder from '@/views/exercise-builder/Edit'
import Invitations from '@/views/organization/Invitations'
import Licenses from '@/views/organization/Licenses'
import Pathways from '@/views/pathways/Pathways'
import Profile from '@/views/Profile'
import Reports from '@/views/Reports'
import Scores from '@/views/performance/Scores'
import Submissions from '@/views/performance/Submissions'
import TeamView from '@/views/teams/TeamView'
import Teams from '@/views/teams/Teams'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Authentication,
    meta: {
      layout: 'plain-layout',
      public: true
    }
  },

  {
    path: '/capability/:domain',
    component: Capability
  },

  {
    path: '/capabilities/overview',
    component: CapabilitiesOverview
  },

  {
    path: '/create-pathway',
    component: CreatePathway
  },

  {
    path: '/dashboard',
    component: Dashboard
  },

  {
    path: '/edit-pathway/:id',
    component: EditPathway
  },

  {
    path: '/exercise-builder/edit/:docId',
    component: ExerciseBuilder
  },

  {
    path: '/exercise-builder/list',
    component: CustomExercises
  },

  {
    path: '/exercise-builder/new',
    component: ExerciseBuilder
  },
  
  {
    path: '/pathways',
    component: Pathways
  },
  
  {
    path: '/organization/administrators',
    component: Administrators
  },

  {
    path: '/organization/invitations',
    component: Invitations
  },

  {
    path: '/organization/licenses',
    component: Licenses
  },

  {
    path: '/profile/:id',
    component: Profile
  },

  {
    path: '/scores',
    component: Scores
  },

  {
    path: '/submissions',
    component: Submissions
  },

  {
    path: '/reports',
    component: Reports
  },

  {
    path: '/teams',
    component: Teams
  },

  {
    path: '/team/:id',
    component: TeamView
  }
]

const router = new VueRouter({
  routes,
})

export default router
