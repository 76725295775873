<template>
  <v-container>
    <v-layout v-if="orgTeams">
      <v-card
        color="grey lighten-4"
        flat
        height="200px"
        width="1200px"
        tile
        style="margin: 10px auto;"
        >
        <v-toolbar flat dark dense color="#198f3d">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-toolbar-title>Teams</v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- Create Button -->
          <v-btn
            color="#198f3d"
            depressed
            @click="toggleDialog"
            v-if="isAdmin"
          >
            Create
          </v-btn>
        </v-toolbar>
          <!-- Member Table -->
          <v-data-table
            :headers="headers"
            :items="orgTeams"
            :items-per-page="10"
            class="elevation-1 green-header"
            >
            <template v-slot:item.name="{ item }">
              <router-link :to="`/team/${item.id}`" style="text-decoration:none;">
                {{ item.name || 'no name'}}
              </router-link>
            </template>

            <!-- Team Leaders -->
            <template v-slot:item.teamleaders="{ item }">
              <!-- Manager Chip -->
              <v-chip
                color="lighten-3"
                label
                style="margin: 0 5px;"
                v-for="(manager, index) in item['team-leaders']"
                :key="index"
                >
                <v-icon left>
                  mdi-account
                </v-icon>

                {{ manager }}
              </v-chip>
            </template>
            
            <!-- Team Members -->
            <template v-slot:item.members="{ item }">
              <!-- If team has members -->
              <v-avatar v-if="item.members.length > 0"  color="green" size="26">
                <span style="font-size:0.7rem;color:white;font-weight:400;">
                  {{ item.members.length}}
                </span>
              </v-avatar>

              <!-- If no members in the team -->
              <v-avatar v-else color="red lighten-2" size="26">
                <span style="font-size: 0.7rem; color: white; font-weight: 400;">
                  0
                </span>
              </v-avatar>
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon @click="deleteItem(item)" small>
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:no-data>
              <v-alert style="width: 100%; margin-top: 20px;" type="info">
                No Teams
              </v-alert>
            </template>

          </v-data-table>

        <!-- Create Team Dialog -->
        <CreateTeamDialog :open="dialog" :toggleDialog="toggleDialog"/>
      </v-card>
    </v-layout>

    <!-- Progress Bar -->
    <v-progress-linear v-else color="green" indeterminate></v-progress-linear>   
  </v-container>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'
import user from '@/mixins/user.js'

// components
import CreateTeamDialog from '@/components/ui/dialogs/CreateTeamDialog'
import TeamCard from '@/components/cards/TeamCard'

// view
export default {
  mixins: [organisation,user],

  data () {
    return {
      dialog: false,
      teams: null,
      search: '',

      headers: [
        { text: 'Team', align: 'left', value: 'name' },
        { text: 'Managers', value: 'teamleaders' },
        { text: 'Members', value: 'members', align: 'right' }
      ],
    }
  },

  methods: {
    toggleDialog () {
      this.dialog = !this.dialog
    },
  },

  components: {
    CreateTeamDialog,
    TeamCard
  }
}
</script>

<style>

</style>