var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.orgTeams)?_c('v-layout',[_c('v-card',{staticStyle:{"margin":"10px auto"},attrs:{"color":"grey lighten-4","flat":"","height":"200px","width":"1200px","tile":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","dense":"","color":"#198f3d"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-group")])],1),_c('v-toolbar-title',[_vm._v("Teams")]),_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"#198f3d","depressed":""},on:{"click":_vm.toggleDialog}},[_vm._v(" Create ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1 green-header",attrs:{"headers":_vm.headers,"items":_vm.orgTeams,"items-per-page":10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/team/" + (item.id))}},[_vm._v(" "+_vm._s(item.name || 'no name')+" ")])]}},{key:"item.teamleaders",fn:function(ref){
var item = ref.item;
return _vm._l((item['team-leaders']),function(manager,index){return _c('v-chip',{key:index,staticStyle:{"margin":"0 5px"},attrs:{"color":"lighten-3","label":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account ")]),_vm._v(" "+_vm._s(manager)+" ")],1)})}},{key:"item.members",fn:function(ref){
var item = ref.item;
return [(item.members.length > 0)?_c('v-avatar',{attrs:{"color":"green","size":"26"}},[_c('span',{staticStyle:{"font-size":"0.7rem","color":"white","font-weight":"400"}},[_vm._v(" "+_vm._s(item.members.length)+" ")])]):_c('v-avatar',{attrs:{"color":"red lighten-2","size":"26"}},[_c('span',{staticStyle:{"font-size":"0.7rem","color":"white","font-weight":"400"}},[_vm._v(" 0 ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"type":"info"}},[_vm._v(" No Teams ")])]},proxy:true}],null,false,1788132562)}),_c('CreateTeamDialog',{attrs:{"open":_vm.dialog,"toggleDialog":_vm.toggleDialog}})],1)],1):_c('v-progress-linear',{attrs:{"color":"green","indeterminate":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }