import firebase from 'firebase/app'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categories: [],
    curriculums: null,
    exercises: []
  },

  getters: {
    // Categories
    getCategories: state => {
      return state.categories
    },

    // Curriculums
    getCurriculums: state => {
      return state.curriculums
    },

    // Exercises
    getExercises: state => {
      return state.exercises
    }
  },

  actions: {
    /*
     * Retrieve the curriculums that the user has access to
     */
    async getCurriculums ({commit}) {
      var call = firebase.functions().httpsCallable('jsonGetCurriculums?timestamp=' + new Date().getTime())

      await call()
        .then(async (response) => {
          if (response.data) {
            await commit('setCurriculums', response.data || {})
          }
        })
        .catch(error => {
          console.error(error)
        })
    },

    /*
     * Retrieve the JSON file that provides all the exercises and features that
     * the user has unlocked
     */
    async getJSONs ({commit}) {
      var call = firebase.functions().httpsCallable('jsonGet?timestamp=' + new Date().getTime())

      await call()
        .then(async (response) => { 
          if (response.data) {
            /* eslint-disable no-multi-spaces */
            await commit('setCategories', (response.data['categories']        || []))
            await commit('setExercises',  (response.data['exercises']         || []))
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  },

  mutations: {
    //
    setCurriculums (state, curriculums) {
      state.curriculums = curriculums
    },
    
    //
    setExercises (state, exercises) {
      state.exercises = exercises
    },

    //
    setCategories (state, categories) {
      state.categories = categories

      // Sort the category by `label`
      state.categories = state.categories.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }

        if (a.label > b.label) {
          return 1
        }

        return 0
      })
    }
  }
})
