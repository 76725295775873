<template>
  <v-container class="mx-auto pt-20 width-700">
    <ActivityMonitor />
    <Users class="mt-20" />
    <Performance class="mt-20" />
    <SkillRange class="mt-20" :data="scores" :dashboard="true" />
    <InvitationForm class="mt-20" />
  </v-container>
</template>

<script>
// components
import ActivityMonitor from '@/components/cards/analytics/ActivityMonitor'
import InvitationForm from '@/components/cards/InvitationForm'
import Performance from '@/components/cards/analytics/Performance'
import SkillRange from '@/components/cards/analytics/SkillRange'
import Users from '@/components/cards/analytics/Users'

// Mixins
import organisation from '@/mixins/organisation.js'

// view
export default {
  mixins: [organisation],

  components: {
    ActivityMonitor,
    SkillRange,
    InvitationForm,
    Performance,
    Users
  }
}
</script>

<style>

</style>