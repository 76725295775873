<template>
  <v-card>
    <v-card-title class="green">
      <div class="headline white--text">
        Performance Analytics
      </div>
    </v-card-title>

    <v-divider />

    <v-card-text class="pb-20 pt-20">
      <v-layout>
        <v-flex v-for="(item, index) in items" :key="index">
          <div class="analytics-value">
            {{ item.value }}
          </div>

          <div class="analytics-title">
            {{ item.label }}
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'

//
export default {
  mixins: [organisation],

  computed: {
    /*
     *
     */
    items () {
      return [
        {
          label: '10+ Submissions',
          value: this.moreThan10Submissions
        },

        {
          label: '50+ Submissions',
          value: this.moreThan50Submissions
        },

        {
          label: '100+ Submissions',
          value: this.moreThan100Submissions
        }
      ]
    },

    /*
     *
     */
    moreThan10Submissions () {
      return this.scores
        .filter(s => s.exercisesCompleted && s.exercisesCompleted.total && s.exercisesCompleted.total > 10)
        .length
    },

    /*
     *
     */
    moreThan50Submissions () {
      return this.scores
        .filter(s => s.exercisesCompleted && s.exercisesCompleted.total && s.exercisesCompleted.total > 50)
        .length
    },

    /*
     *
     */
    moreThan100Submissions () {
      return this.scores
        .filter(s => s.exercisesCompleted && s.exercisesCompleted.total && s.exercisesCompleted.total > 100)
        .length
    }
  }
}
</script>

<style>

</style>