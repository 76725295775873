<template>
  <v-container class="pt-20">
    <!-- Loarding Bar -->
    <v-progress-linear v-if="!scores" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Scores -->
    <v-card v-else width="1200px">
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>mdi-chart-bar</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>Scores</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="scores"
        :items-per-page="-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="green-header"
        >
        <template v-slot:item.email="{ item }">
          <router-link :to="`/profile/${item.id}`" style="text-decoration:none;">
            {{ getEmailAddress(item.id) }}
          </router-link>
        </template>

        <template v-slot:item.totalPoints="{ item }">
          {{ item.totalPoints || 0 }}
        </template>

        <template v-slot:item.exercisesCompleted="{ item }">
          {{ item.exercisesCompleted.total || 0 }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'

// view
export default {
  mixins: [organisation],

  data () {
    return {
      headers: [
        { text: 'Email', value: 'email' },
        { text: 'Score', value: 'totalPoints'},
        { text: 'Nb. Exercises', value: 'exercisesCompleted'},
        { text: 'Nb. Logins', value: 'active-logins'},
        { text: 'Last Seen', value: 'last-active-date'}
      ],

      sortBy: 'totalPoints',
      sortDesc: true
    }
  }
}
</script>

<style>

</style>