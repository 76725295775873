<template>
  <v-container>
    <!-- Progress Bar -->
    <v-progress-linear v-if="!orgTeams || !settingsDocs" color="green" indeterminate></v-progress-linear>   

    <!-- Permissions -->
    <v-card v-else width="1200px">
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>mdi-key-variant</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>Permissions</v-toolbar-title>
      </v-toolbar>

      <!-- Search -->
      <v-card-title>
        <v-text-field v-model="search"
          append-icon="mdi-magnify"
          color="green"
          hide-details
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items-per-page="-1"
        :items="tableData"
        :search="search"
        class="elevation-1 green-header"
        hide-default-footer
        sort-by="email"
        >
        <!-- Administrator Checkbox -->
        <template v-slot:item.admin="{ item }">
          <v-checkbox v-model="item.admin"
            @change="handleAdminChange($event, item)"
            class="permission-check"
            color="green"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import firebase from 'firebase'

// mixins
import organisation from '@/mixins/organisation.js'
import snack from '@/stores/ui.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// view
export default {
  mixins: [
    organisation,
    ui,
    user
  ],

  data () {
    return {
      headers: [
        {
          text: 'User',
          align: 'left',
          value: 'email',
          sortable: false
        },

        {
          text: 'Admin?',
          value: 'admin',
          width: '120px',
          align: 'center',
          sortable: false
        }
      ],
      
      search: null
    }
  },

  computed: {
    /* 
     * Returns the data to load in the table
     */
    tableData () {
      var userData = []

      // iterate through list of organisation members and add them to the array
      this.settingsDocs.forEach(doc => {
        var user = {}
        user.email = doc.email || 'no email detected. error'
        user.admin = this.isUserAdmin(user.email)

        userData.push(user)
      })

      return userData
    }
  },

  methods: {
    /*
     * Functions to Handle Checkbox Changes
     */
    handleAdminChange (status, user) {
      if (user.admin) {
        this.addAdmin(user.email)
      } else {
        this.removeAdmin(user.email)
      }
    },

    /*
     *
     */
    async addAdmin (email) {
      var id = this.getUserUID(email)

      await firebase.firestore()
        .collection('settings')
        .doc(id)
        .update({
          'roles': firebase.firestore.FieldValue.arrayUnion('organisation-administrator')
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', `${email} Added as Administrator`)
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     *
     */
    async removeAdmin (email) {
      var id = this.getUserUID(email)

      // PEBKAC check
      if (id === this.uid) {
        return this.throwError('You cannot remove your own super user permissions')
      }

      await firebase.firestore()
        .collection('settings')
        .doc(id)
        .update({
          'roles': firebase.firestore.FieldValue.arrayRemove('organisation-administrator')
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', `${email} No Longer an Administrator`)
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })
    }
  }
}
</script>

<style>

</style>
