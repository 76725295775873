<template>
  <v-container>
    <!-- Loarding Bar -->
    <v-progress-linear v-if="!items" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Card -->
    <v-card v-else>
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>
            mdi-chart-scatter-plot
          </v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          Demonstrated Capabilities
        </v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="green-header"
        >
        <template v-slot:item.category="{ item }">
          <router-link :to="`/capability/${item.category}`" class="no-decoration">
            {{ formatText(item.category) }}
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import utils from '@/mixins/utils.js'

// view
export default {
  mixins: [organisation, platform, utils],

  data () {
    return {
      headers: [
        {
          text: 'Cyber Domain',
          value: 'category'
        },

        {
          text: 'Points',
          value: 'points'
        }
      ],

      sortBy: 'points',
      sortDesc: true
    }
  },

  computed: {
    /*
     *
     */
    items () {
      var keys = {}
      var res = []

      // create the basic objects
      for (var v = 0; v < this.categories.length; v++) {
        var category = this.categories[v]

        if (category.uid) {
          keys[category.uid] = 0
        }
      }

      // iterate over the scores
      for (var i = 0; i < this.scores.length; i++) {
        var doc = this.scores[i]

        if (!doc.exercisesPoints) {
          continue
        }

        var points = doc.exercisesPoints

        // iterate over the categories
        for (var j = 0; j < this.categories.length; j++) {
          var key = this.categories[j]

          if (!key.uid) {
            continue
          }

          key = key.uid

          if (points[key] && typeof points[key] === 'number') {
            keys[key] += points[key]
          }
        }
      }

      // create an array for the v-data-table
      Object.keys(keys).forEach(category => {
        res.push({
          category: category,
          points: keys[category]
        })
      })

      return res
    }
  }
}
</script>

<style>

</style>