<template>
  <v-container>
    <!-- Loading Bar -->
    <v-progress-linear v-if="!submissions" color="green" :indeterminate="true"></v-progress-linear>

    <!-- Submissions -->
    <v-card v-else width="1200px">
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>mdi-file-upload-outline</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>Submissions</v-toolbar-title>
      </v-toolbar>

      <Submissions :data="items" />
    </v-card>
  </v-container>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import user from '@/stores/user.js'

// Components
import Submissions from '@/components/Submissions'

// view
export default {
  mixins: [
    organisation,
    platform,
    user
  ],

  data () {
    return {
      comments: null,
    }
  },

  computed: {
    /*
     * Generate an array of objects that can be displayed in the table
     * and searched via the user input.
     */
    items () {
      var res = Object.assign([], this.submissions)
        .map(e => {
          var exercise = this.getExercise(e.exercise)
          var title = (exercise) ? exercise.title : 'unknown'

          return Object.assign(e, {
            ...e,
            email: this.getEmailAddress(e.student).split('@')[0],
            title: title,
            uid: e.student
          })
        })
      
      return res
    }
  },

  components: {
    Submissions
  }
}
</script>

<style>

</style>