<template>
  <div v-if="orgPathways">
    <v-list class="pd-0" three-line>
      <v-list-group v-model="item.active" v-for="item in orgPathways" :key="item.label"
        no-action
        >
        <!-- Pathway Title & Description -->
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="capitalize bold text--green">
              {{ item['curriculum-title'] }}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <!-- Section Title & Description -->
        <v-list dense v-for="(mod, index) in item['training-modules']" :key="index" :style="mobileStyle">
          <!-- Section Title -->
          <v-list-item-title class="bold capitalize">
            {{ mod.title }}:
          </v-list-item-title>

          <!-- Section Exercises -->
          <v-list-item-subtitle v-for="(uuid, i) in mod.exercises" :key="i">
            <div style="margin: 1px 1px 1px 30px; padding:5px;">
              <v-icon color="green" dense>
                mdi-checkbox-blank-circle
              </v-icon>

              <span style="padding-left:5px;">
                {{ getExerciseTitle(uuid) }}
              </span>
            </div>
          </v-list-item-subtitle>
        </v-list>

        <!-- Edit/Delete Buttons -->
        <v-card-actions style="padding-right:50px;" class="d-flex justify-end">
          <v-btn
            @click="editPathway(item)"
            color="green"
            depressed
            outlined
            :to="`/edit-pathway/${item.id}`"
          >
            Edit
          </v-btn>

          <v-btn
            @click="deletePathway(item)"
            color="green"
            depressed
            outlined
          >
            remove
          </v-btn>
        </v-card-actions>
      </v-list-group>
    </v-list>

    <!-- Remove pathway progress box -->
    <ProgressDialog :loading="loading" text="Removing Pathway"/>

    <RemoveAlert :selected="selected" :title="selected.label" v-on:confirm="handleRemove($event)" />
  </div>
</template>

<script>
// firebase
import firebase from 'firebase'

// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import platform from '@/mixins/platform.js'
import ui from '@/mixins/ui.js'
import user from '@/mixins/user.js'

// components
import ProgressDialog from '@/components/ui/dialogs/ProgressDialog'
import RemoveAlert from '@/components/ui/alerts/RemoveAlert'

export default {
  props:['mobileStyle'],

  mixins:[
    firestore,
    organisation,
    platform,
    ui,
    user
    ],

  data () {
    return {
      loading: false,
      selected: ''
    }
  },

  methods: {
    /*
     *
     */
    deletePathway (item) {
      this.selected = item
      this.toggleAlert
    },

    /*
     *
     */
    editPathway (item) {
      this.selected = item
    },

    /*
     *
     */
    async handleRemove (confirm) {
      if (confirm) {
        this.fsDelete('custom-pathways', this.selected.id)
      }
    }
  },

  components: {
    ProgressDialog,
    RemoveAlert
  }
}
</script>

<style>

</style>