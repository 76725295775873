<template>
  <div>
    <!-- List Specifications -->
    <v-text-field v-for="(spec, index) in specifications" :key="index"
      :value="spec"
      append-outer-icon="mdi-minus"
      color="green"
      dense
      readonly
      @click:append-outer="removeSpecification(spec)"
    ></v-text-field>

    <!-- Add specification -->
    <v-text-field v-model="specification"
      append-outer-icon="mdi-plus"
      class="mt-2"
      color="green"
      dense
      filled
      required
      @click:append-outer="addSpecification"
    ></v-text-field>
  </div>
</template>

<script>
// component
export default {
  props: ['value'],

  data () {
    return {
      specification: '',
      specifications: []
    }
  },

  computed: {
    /*
     * Standard VueJS code to allow v-model from the parent
     */
    fieldModelValue: {
      get () {
        return this.value
      },
      
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  watch: {
    /*
     * Emit updates to the parent component
     */
    specifications (newVal) {
      this.$emit('input', newVal)
    }
  },

  methods: {
    /*
     * Add a new specification to the list
     */
    addSpecification () {
      this.specifications.push(this.specification)
      this.specification = ''
    },

    /*
     * Remove a specification
     */
    removeSpecification (val) {
      this.specifications = this.specifications.filter(s => s !== val)
    }
  },

  /*
   * Design pattern to sync the local variable with the value from v-model
   */
  mounted () {
    if (Array.isArray(this.value) && this.value.length > 0) {
      this.specifications = this.value
    }
  }
}
</script>

<style>

</style>