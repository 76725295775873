<template>
  <v-card>
    <v-card-title class="green">
      <div class="headline white--text">
        Activity Monitor
      </div>
    </v-card-title>

    <v-divider />

    <v-card-text class="pb-20 pt-20">
      <v-layout>
        <v-flex v-for="(item, index) in items" :key="index">
          <div class="analytics-value">
            {{ item.value }}
          </div>

          <div class="analytics-title">
            {{ item.label }}
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'

// mixins
import organisation from '@/mixins/organisation.js'

//
export default {
  mixins: [organisation],

  computed: {
    /*
     *
     */
    items () {
      return [
        {
          label: 'Total Score',
          value: this.totalScore
        },

        {
          label: 'Last Submitted',
          value: this.lastSubmissionAgo
        }
      ]
    },

    /*
     *
     */
    lastSubmissionAgo () {
      var last = this.submissions.sort((a, b) => new Date(b.timestamp.toDate()) - new Date(a.timestamp.toDate()))

      if (!Array.isArray(last) || !last.length > 0) {
        return 'n/a'
      }

      last = last[0].timestamp.toDate().toString()

      return moment(last).fromNow()
    },

    /*
     *
     */
    totalScore () {
      var points = this.scores
        .filter(s => s.totalPoints && s.totalPoints > 0)
        .map(s => s.totalPoints)

      return points.reduce((total, n) => (total + n))
    }
  }
}
</script>

<style>

</style>