<template>
  <div>
    <v-tabs :style="mobileStyle" color="green" v-model="tab">
      <v-tab href="#search-by-course">
        Search by Course
      </v-tab>

      <v-tab href="#search-by-domain">
        Search by Domain
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Search by Course -->
      <v-tab-item value="search-by-course" v-if="tab === 'search-by-course'">
        <v-card :style="mobileStyle">
          <v-card-title>
            {{ selectedCurriculum.title }}
          </v-card-title>

          <v-container>
            <v-row>
              <v-flex>
                <v-select v-model="selectedCurriculum" 
                  :items="curriculumOptions"
                  color="green"
                  item-text="title"
                  label="Course"
                  return-object
                  style="width: 300px; margin-left: 20px;"
                ></v-select>
              </v-flex>

              <v-flex>
                <v-select v-model="selectedModule"
                  :items="curriculumModules"
                  color="green"
                  item-text="title"
                  label="Module"
                  return-object
                  style="width: 300px; margin-left: 20px;"
                ></v-select>
              </v-flex>
            </v-row>
          </v-container>

          <v-data-table v-model="selected"
            :headers="headers"
            :items="filteredExercises"
            :search="search"
            :single-select="singleSelect"
            @item-selected="onCheckboxChange"
            class="green-header"
            item-key="title"
            show-select
            sort-by="title"
            style="margin:0px;"
          ></v-data-table>
        </v-card>
      </v-tab-item>

      <!-- Search by Domain -->
      <v-tab-item value="search-by-domain" v-if="tab === 'search-by-domain'">
        <v-card :style="mobileStyle">
          <v-card-title>
            {{ category.label }}
            
            <v-spacer></v-spacer>

            <v-text-field v-model="search"
              append-icon="mdi-magnify"
              color="green"
              hide-details
              label="Search"
              single-line
              style="max-width: 300px; float: right;"
            ></v-text-field>
          </v-card-title>
          
          <v-select v-model="category" 
            :items="categories"
            color="green"
            item-text="label"
            label="Category"
            return-object
            style="width: 300px; margin-left: 20px;"
          ></v-select>

          <v-data-table v-model="selected"
            :headers="headers"
            :items="filteredExercises"
            :search="search"
            :single-select="singleSelect"
            @item-selected="onCheckboxChange"
            class="green-header"
            item-key="title"
            show-select
            sort-by="title"
            style="margin:0px;"
          ></v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// mixins
import helpers from '@/mixins/helpers.js'
import platform from '@/mixins/platform.js'

// component
export default {
  props: ['checkedHandler', 'uncheckedHandler', 'sectionExercises', 'singleSelect'],

  mixins: [helpers, platform],

  data () {
    return {
      category: {
        label: "Windows Internals",
        uid: "windows-internals"
      },

      selectedCurriculum: {
        sku: 'mics-introduction-to-cyber-security',
        title: 'MICS - Introduction to Cyber Security'
      },

      selectedModule: {
        code: 'MICS-001',
        title: 'Professional Writing'
      },

      search: '',
      selected: [],
      tab: null,

      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'Title',
          value: 'title'
        },

        {
          align: 'left',
          sortable: true,
          text: 'Difficulty',
          value: 'difficulty'
        }
      ]
    }
  },

  computed: {
    /*
     * Return the curriculum that the user has selected from the UI
     */
    curriculum () {
      // validation
      if (!Array.isArray(this.curriculums)) {
        return null
      }

      var res = this.curriculums.filter(c => c.sku === this.selectedCurriculum.sku)

      // validation
      if (!res || !res.length) {
        return null
      }

      return res[0]
    },

    /*
     * Returns the modules available in the selected curriculum
     */
    curriculumModules () {
      // validation
      if (!this.curriculum || !this.curriculum['training-modules']) {
        return []
      }

      return this.curriculum['training-modules'].map(m => {
        return {
          code: m.code,
          title: m.title
        }
      })
    },

    /*
     * Return an array of curriculums that are available for selection
     */
    curriculumOptions () {
      // validation
      if (!Array.isArray(this.curriculums)) {
        return []
      }

      return this.curriculums
        .filter(c => ['dcwf-curriculum', 'mcsi-bootcamp', 'mcsi-curriculum'].includes(c.type))
        .map(c => {
          return {
            sku: c.sku,
            title: c['curriculum-title']
          }
        })
    },

    /*
     * Returns the exercises available for selection
     */
    filteredExercises () {
      // return the exercises selected in a given domain
      if (this.tab === 'search-by-domain') {
        return this.exercises.filter(item => item.groupUID === this.category.uid)
      }

      // return the exercises selected based on a given course module
      if (this.tab === 'search-by-course') {
        var sku = this.selectedCurriculum.sku
        var code = this.selectedModule.code

        // validation
        if (!sku || !code) {
          return []
        }

        // retrieve the data
        var course = this.curriculums.filter(c => c.sku === sku)[0]
        var mod = course['training-modules'].filter(m => m.code === code)

        // validation
        if (!mod || !mod.length) {
          return []
        }

        var exercises = mod[0]['exercises']

        if (!Array.isArray(exercises) || !exercises.length) {
          return []
        }

        // exercises must be recovered
        if (typeof exercises[0] === 'string') {
          return this.exercises.filter(e => exercises.includes(e.uuid))
        } else {
          // exercises are listed in the module as objects
          return exercises
        }
      }

      return []
    },

    /*
     * Change the style of component depending on whether we are on a mobile phone or not
     */
    mobileStyle () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "margin:20px;"
      } else {
        return "margin:0;"
      }
    }
  },

  methods: {
    /*
     * Event handler for when an exercise is selected or removed
     */
    onCheckboxChange (item) {
      if (item.value) {
        // Add Exercise Object
        this.checkedHandler(item.item)
      } else {
        this.uncheckedHandler(item.item.uuid)
      }
    }
  },

  watch: {
    /*
     *
     */
    sectionExercises () {
      // Resets table if selected exercises are cleared
      if (this.sectionExercises.length === 0) {
        this.selected = []
      }
    }
  }
}
</script>

<style>

</style>