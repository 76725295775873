<template>
  <div>
    <!-- App UI -->
    <div v-if="loaded">
      <div>
        <!-- eslint-disable -->
        <Menu />

        <v-app-bar app flat dark dense color="green">
          <v-app-bar-nav-icon @click="toggleMenu"></v-app-bar-nav-icon>
          
          <v-spacer></v-spacer>
          
          <v-toolbar-title>
            Teams Management Portal
          </v-toolbar-title>

          <v-spacer />
        </v-app-bar>

        <v-main>
          <slot />

          <Footer />
        </v-main>
      </div>
    </div>

    <!-- Progress Bar -->
    <v-progress-linear v-else-if="!authenticated" color="red" :indeterminate="true"></v-progress-linear>

    <!-- Unauthorized -->
    <div v-else-if="!authorized">
      Unauthorized
    </div>

    <!-- Progress Bar -->
    <v-progress-linear v-else color="green" :indeterminate="true"></v-progress-linear>
  </div>
</template>

<script>
// stores
import ui from '@/stores/ui.js'

// mixins
import organisation from '@/mixins/organisation.js'
import user from '@/mixins/user.js'

// components
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer'

// view
export default {
  mixins: [organisation, user],

  computed: {
    /*
     * Returns TRUE if the user is authentication
     */
    authenticated () {
      return this.email
        && this.uid
        && this.getSettings
        && this.getSettings.email
    },

    /*
     * Returns TRUE if the user is authorized
     */
    authorized () {
      return this.authenticated
        && this.orgID
        && this.isAdmin
    },

    /*
     * Returns TRUE if the application has loaded
     */
    loaded () {
      return this.authenticated
        && this.authorized
        && this.orgEmails.length > 0
        && this.scores.length > 0
    }
  },

  methods: {
    toggleMenu () {
      ui.commit('toggleMenu')
    }
  },

  components: {
    Footer,
    Menu
  }
}
</script>

<style>
.v-application .green--text {
  color: #198f3d !important;
  caret-color: #198f3d !important;
}

.v-application .green,
.v-application .success {
  background-color: #198f3d !important;
  border-color: #198f3d !important;
}
</style>
