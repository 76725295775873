// mixins
import organisation from '@/mixins/organisation.js'
import user from '@/mixins/user.js'

export default {
  mixins: [
    organisation,
    user
  ],

  computed: {
    /*
     * Returns TRUE if the user can access the curriculum builder
     */
    unlockedCurriculumBuilder () {
      return this.unlockedExerciseBuilder
    },

    /*
     * Returns TRUE if the user can access the exercise builder
     */
    unlockedExerciseBuilder () {
      return this.hasPermission('course-builder')
    },

    /*
     * Returns TRUE if the user can access the reporting feature
     */
    unlockedReporting () {
      return this.hasPermission('reporting')
    },

    /*
     * Returns TRUE if the user can access the teams management feature
     */
    unlockedTeamsManagement () {
      return this.hasPermission('teams-management')
    },

    /*
     * Returns TRUE if the user can access the transition memory
     */
    unlockedTransitionMemory () {
      return this.hasPermission('transition-memory')
    }
  }
}