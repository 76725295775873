<template>
  <v-container>
    <div class="footer">
      © {{ year }} Copyright Mossé Cyber Security
    </div>
  </v-container>
</template>

<script>
export default {
  app: 'Footer',

  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style>
.footer {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}
</style>
