import firebase from 'firebase'

// stores
import platform from '@/stores/platform.js'

// mixins
import ui from '@/mixins/ui.js'

// mixin
export default {
  mixins: [ui],

  data () {
    return {
      difficulties: [
        'Concepts',
        'Novice',
        'Advanced Beginner',
        'Competent'
      ]
    }
  },

  computed: {
    /*
     * Returns all of the exercises that the user can see
     */
    exercises () {
      return platform.getters.getExercises
    },

    /*
     * Returns all of the unlocked categories
     */
    categories () {
      return platform.getters.getCategories
    },

    /*
     * Returns all of the curriculums the user has unlocked
     */
    curriculums () {
      return platform.getters.getCurriculums
    }
  },

  methods: {
    /*
     * Opens a file stored in Google Cloud Storage
     */
    async bucketOpen (filename) {
      await firebase.storage()
        .ref(filename)
        .getDownloadURL()
        .then(url => {
          return window.open(url, '_blank')
        })
        .catch(error => {
          this.throwError(error)
        })
    },

    /*
     * Retrieve an exercise based on its UUID
     */
    getExercise (uuid) {
      var exercise = this.exercises.filter(e => e.uuid === uuid)

      if (Array.isArray(exercise) && exercise.length) {
        return exercise[0]
      }

      return null
    },

    /*
     * Retrieve an exercise's title
     */
    getExerciseTitle (uuid) {
      var exercise = this.getExercise(uuid)

      if (exercise) {
        return exercise.title
      }

      return null
    }
  }
}
