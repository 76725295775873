<template>
  <v-app>
    <component :is="layout">
      <!-- View -->
      <router-view />

      <!-- Global Snackbar -->
      <v-snackbar
        :value="snackbar"
        :color="snackbarColor"
        :multi-line="true"
        :timeout="snackbarTimeout"
        >
        {{ snackbarText }}
        <v-btn
          dark
          text
          @click="closeSnackbar"
          >
          Close
        </v-btn>
      </v-snackbar>

      <v-snackbar :value="persistentSnackbarText"
        :bottom="true"
        :multi-line="true"
        :timeout="-1"
        color="green"
        >
        {{ persistentSnackbarText }}

        <v-btn
          @click="closePersistentSnackbar"
          dark
          text
          >
          Close
        </v-btn>
      </v-snackbar>
    </component>
  </v-app>
</template>

<script>
// CSS
import '@/assets/css/cards.css'
import '@/assets/css/helpers.css'
import '@/assets/css/ui.css'

// firebase
import '@firebase/firestore'
import firebase from 'firebase/app'

// stores
import organisation from '@/stores/organisation.js'
import platform from '@/stores/platform.js'
import ui from '@/stores/ui.js'
import user from '@/stores/user.js'

// View
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'application-layout')
    },

    persistentSnackbar () {
      return ui.getters.persistentSnackBar
    },

    persistentSnackbarText () {
      return ui.getters.persistentSnackBarText
    },

    snackbar () {
      return ui.getters.snackbarStatus
    },

    snackbarText () {
      return ui.getters.snackbarText
    },

    snackbarColor () {
      return ui.getters.snackbarColor
    },

    snackbarTimeout () {
      return ui.getters.snackbarTimeout
    },

  },

  methods: {
    closePersistentSnackbar: function () {
      ui.commit('closePersistentSnackbar')
    },

    closeSnackbar: function () {
      ui.commit('closeSnackbar')
    }
  },

  components: {
  },

  data () {
    return {
      loaded: false
    }
  },

  beforeCreate () {
    firebase.auth().onAuthStateChanged(async (u) => {
      if (u) {
        // register basic user information
        await user.commit('setEmail', u.email)
        await user.commit('setUid', u.uid)

        // retrieve additional user information
        await user.dispatch('bindSettings')
        await user.commit('setProfile')

        // retrieve the organization identifier
        await organisation.commit('setOrgID')

        // retrieve the organisation document
        await organisation.dispatch('bindOrgDoc')

        // retrieve the exercises first (we do this to avoid a race condition)
        await platform.dispatch('getJSONs')
        await platform.dispatch('getCurriculums')

        // retrieve and populate the platform's data based on the user's permissions
        organisation.dispatch('bindLicenses')
        organisation.dispatch('bindPathways')
        organisation.dispatch('bindReports')
        organisation.dispatch('bindScores')
        organisation.dispatch('bindSettingsDocs')
        organisation.dispatch('bindSubmissions')
        organisation.dispatch('bindTeams')
      }
    })
  }
}
</script>

<style>

</style>