<template>
  <div>
    <v-container>
      <!-- Progress Bar -->
      <v-progress-linear v-if="!loaded" color="green" indeterminate></v-progress-linear>

      <!-- Invitations -->
      <v-card v-else width="1200px">
        <v-toolbar flat dark dense color="green">
          <v-list-item-icon>
            <v-icon>
              mdi-account-group
            </v-icon>
          </v-list-item-icon>

          <v-toolbar-title>
            Invitations
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            @click="dialog = true"
            color="green darken-4"
            flat
            small
            >
            <v-icon left>
              mdi-plus
            </v-icon>

            Invite
          </v-btn>
        </v-toolbar>

        <!-- Search -->
        <v-card-title>
          <v-text-field v-model="search"
            append-icon="mdi-magnify"
            color="green"
            hide-details
            label="Search"
            single-line
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items-per-page="-1"
          :items="invitations"
          :search="search"
          class="elevation-1 green-header"
          hide-default-footer
          sort-by="email"
          >
        </v-data-table>
      </v-card>

      <!-- Invitation Dialog -->
      <v-dialog v-model="dialog" width="500">
        <InvitationForm />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import '@firebase/firestore'
import firebase from 'firebase/app'

// mixins
import user from '@/mixins/user.js'

// components
import InvitationForm from '@/components/cards/InvitationForm'

export default {
  mixins: [user],

  data () {
    return {
      dialog: false,

      headers: [
        {
          text: 'Email',
          value: 'email',
          sortable: false
        },

        {
          text: 'Accepted',
          value: 'accepted',
          sortable: false,
          width: '120px'
        }
      ],

      invitations: null,
      search: null
    }
  },

  computed: {
    /*
     *
     */
    loaded () {
      return this.invitations
    }
  },

  /*
   *
   */
  firestore () {
    return {
      invitations: firebase.firestore()
        .collection('friends-invitations')
        .where('organisation', '==', this.orgID)
    }
  },

  components: {
    InvitationForm
  }
}
</script>

<style>

</style>