<template>
  <v-navigation-drawer :value="opened" :width="220" app>
    <!-- MCSI Logo -->
    <div class="align-center mt-5">
      <img class="logo-small" src="/images/logo.png"/>
    </div>

    <!-- eslint-disable -->
    <v-list dense>
      <!-- Main -->
      <v-divider></v-divider>
      <v-list-item-group>
        <!-- Dashboard -->
        <v-list-item to="/dashboard" active-class="green--text">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Exercise Builder -->
        <v-list-item to="/exercise-builder/list" active-class="green--text" v-if="unlockedExerciseBuilder">
          <v-list-item-icon>
            <v-icon>mdi-book-edit</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Exercise Builder
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-divider></v-divider>
      
      <!-- Performance -->
      <v-subheader>       
        Performance
      </v-subheader>
      <v-divider></v-divider>

      <v-list-item-group>

        <!-- Scores -->
        <v-list-item to="/scores" active-class="green--text">
          <v-list-item-icon>
            <v-icon>mdi-chart-bar</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Scores
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Capabilities -->
        <v-list-item to="/capabilities/overview" active-class="green--text">
          <v-list-item-icon>
            <v-icon>mdi-chart-scatter-plot</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Capabilities
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Submissions -->
        <v-list-item to="/submissions" active-class="green--text">
          <v-list-item-icon>
            <v-icon>mdi-file-upload-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Submissions
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-divider></v-divider>
      
      <!-- Team Management -->
      <div v-if="showTeamManagement">
        <v-subheader>       
          Team Management
        </v-subheader>
        <v-divider></v-divider>

        <v-list-item-group>
          <!-- Teams -->
          <v-list-item to="/teams" active-class="green--text" v-if="unlockedTeamsManagement">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Teams
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Pathways -->
          <v-list-item to="/pathways" active-class="green--text" v-if="unlockedCurriculumBuilder">
            <v-list-item-icon>
              <v-icon>mdi-map-marker-path</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Curriculum Builder
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Reports -->
          <v-list-item to="/reports" active-class="green--text" v-if="unlockedReporting">
            <v-list-item-icon>
              <v-icon>mdi-file-chart</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                Reports
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider></v-divider>
      </div>

      <!-- Organization -->
      <v-subheader>       
        Organization
      </v-subheader>
      <v-divider></v-divider>

      <v-list-item-group>
        <!-- Invitations -->
        <v-list-item to="/organization/invitations" active-class="green--text">
          <v-list-item-content>
            <v-list-item-title>
              Invitations
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Invitations -->
        <v-list-item to="/organization/licenses" active-class="green--text">
          <v-list-item-content>
            <v-list-item-title>
              Licenses
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Administrators -->
        <v-list-item to="/organization/administrators" active-class="green--text">
          <v-list-item-content>
            <v-list-item-title>
              Administrators
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-divider></v-divider>
      <!-- Account -->
      <v-subheader>       
        Account
      </v-subheader>
      <v-divider></v-divider>

      <v-list-item-group>
        <v-list-item @click="logout" active-class="green--text">
          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// firebase
import firebase from 'firebase'

// mixins
import permissions from '@/mixins/permissions.js'
import user from '@/mixins/user.js'

// stores
import ui from '@/stores/ui.js'
import userStore from '@/stores/user.js'

//
export default {
  mixins: [
    permissions,
    user
  ],

  computed: {
    /*
     * Returns TRUE if the menu is open
     */
    opened () {
      return ui.getters.menu
    },

    /*
     * Returns TRUE if we should the teams management section of the menu
     */
    showTeamManagement () {
      return this.unlockedTeamsManagement
        || this.unlockedCurriculumBuilder
        || this.unlockedReporting
    }
  },

  methods: {
    /*
     * Logout
     */
    async logout () {
      await firebase.auth()
        .signOut()
        .then(() => {
          userStore.commit('reset')

          this.$router.push('/')
        })
    }
  }

}
</script>

<style>
.logo-small {
  max-width: 200px;
}

.v-list--dense .v-subheader {
  background-color: #F5F5F5;
  font-weight: bold;
  padding-left: 20px;
}
</style>
