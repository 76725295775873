<template>           
  <v-card :hover="true" v-if="data.name.includes(search)" class="team-member" :to="`/team/${data.id}`">
    <v-toolbar color="green darken-1" flat dark dense>
      <v-icon style="margin-right:15px;">{{ data.icon }}</v-icon>
      
      <v-toolbar-title>{{ data.name }}</v-toolbar-title>
      <v-spacer></v-spacer>

    <v-btn class="mx-2" icon depressed small color="white">
      <v-icon dark>mdi-delete</v-icon>
    </v-btn>

    </v-toolbar>

    <v-divider />

    <v-card-text class="fs-16">

      <div>
        Mangaer: {{ data.manager }}
      </div>

      <v-icon class="mr-2" small>mdi-account-group</v-icon> {{ data.members.length }}
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: ['search', 'data'],

}
</script>

<style>

</style>