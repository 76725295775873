// Global Validation rules
import user from '@/mixins/user.js'

export default {
  mixins:[user],

  data () {
    return {
      rules: {
        email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      }
    }
  }
}
