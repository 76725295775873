<template>
  <v-card class="member-card">
    <!-- Header -->
    <v-toolbar color="green white--text" flat dense style="margin-bottom: 10px;">
      <v-toolbar-title>
        Add New Team Member
      </v-toolbar-title>
    </v-toolbar>

    <v-form ref="form" v-model="form">      
      <!-- Member Email Combobox -->
      <MemberCombobox :addFunction="setMembers" memberType="team members"/>
    </v-form>

    <v-card-actions>
      <v-spacer />

      <!-- Laptop/Desktop Button -->
      <v-btn v-if="$vuetify.breakpoint.mdAndUp"
        :disabled="!valid"
        @click="addTeamMembers"
        color="green"
        dark
        large
        style="margin-right:15px;"
        >
        Add
      </v-btn>

      <!-- Mobile Button -->
      <v-btn v-else
        :disabled="!valid"
        @click="addTeamMembers"
        block
        color="green"
        dark
        style="margin-left:18%;border:1px solid red;"
        >
        Add
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Mixins
import organisation from '@/mixins/organisation.js'
import snack from '@/stores/ui.js'
import user from '@/mixins/user.js'
import validation from '@/mixins/validation.js'

// Components
import MemberCombobox from '@/components/ui/comboboxes/MemberCombobox'

// View
export default {
  mixins: [organisation, user, validation],

  data () {
    return {
      memberEmails: [],
      form: true
    }
  },

  computed: {
    /*
     *
     */
    valid () {
      return this.memberEmails.length > 0
    }
  },

  methods: {
    /*
     *
     */
    async addTeamMembers () {
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .doc(this.$route.params.id)
        .update({
          members: firebase.firestore.FieldValue.arrayUnion(...this.memberEmails)
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', "Team Member(s) Added to Team")
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })

      this.reset()
    },

    /*
     *
     */
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },

    /*
     *
     */
    setMembers (members) {
      this.memberEmails = members
    }
  },

  components: {
    MemberCombobox
  }
}
</script>

<style>

</style>