<template>
  <v-card flat tile>
    <!-- Dashboard Title -->
    <v-card-title v-if="dashboard" class="green">
      <div class="headline white--text">
        Proficiency Scale
      </div>
    </v-card-title>

    <!-- Profile Title -->
    <v-card-title v-else class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
      Proficiency Scale
    </v-card-title>

    <v-divider />

    <v-card-text class="pb-20 pt-20">
      <!-- Top Row -->
      <v-layout class="mb-40" wrap>
        <v-flex v-for="(item, index) in items.slice(0,2)" :key="index" md6>
          <div class="analytics-value">
            {{ item.value }}
          </div>

          <div class="analytics-title">
            {{ item.label }}
          </div>
        </v-flex>
      </v-layout>

      <!-- Bottom Row -->
      <v-layout wrap>
        <v-flex v-for="(item, index) in items.slice(2,4)" :key="index" md6>
          <div class="analytics-value">
            {{ item.value }}
          </div>

          <div class="analytics-title">
            {{ item.label }}
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>

// View
export default {
  props: ['data', 'dashboard'],

  computed: {
    /*
     *
     */
    items () {
      return [
        {
          label: 'Concept',
          value: this.countCompletedExercises('concepts')
        },

        {
          label: 'Novice',
          value: this.countCompletedExercises('novice')
        },

        {
          label: 'Advanced Beginner',
          value: this.countCompletedExercises('advanced-beginner')
        },

        {
          label: 'Competent',
          value: this.countCompletedExercises('competent')
        }
      ]
    },

  },

  methods: {   
    /*
     * Count completed exercises by difficulty
     */
    countCompletedExercises (difficulty) {
      var count = 0
      // For each team member
      this.data.forEach(score => {
        // For each exercise category
        for(const category in score.exercisesCompleted) {
          // Disregard the 'total' entry
          if(category != 'total') {
            // If the difficulty is what is supplied
            if(score.exercisesCompleted[category][difficulty] > 0) {
              count += score.exercisesCompleted[category][difficulty]
            }
          }
        }
      })
      return count
    }, 
  }
}
</script>

<style>

</style>