<template>
  <v-container>
    <v-card
      class="elevation-1"
      color="grey lighten-4"
      flat
      style="margin: 10px auto;"
      tile
      >
      <!-- Toolbar -->
      <v-toolbar flat dense dark color="#198f3d">
        <v-list-item-icon>
          <v-icon>mdi-map-marker-path</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          Pathways
        </v-toolbar-title>

        <v-spacer />

        <!-- Create Button -->
        <v-btn
          color="#198f3d"
          depressed
          to="/create-pathway"
          >
          Create
        </v-btn>
      </v-toolbar>

      <!-- Custom Pathways -->
      <CustomPathwaysList :mobileStyle="mobileStyle" />
    </v-card>
  </v-container>
</template>

<script>
// components
import CustomPathwaysList from '@/components/ui/lists/CustomPathwaysList'

export default {
  computed: {
    /*
     *
     */
    mobileStyle () {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'padding:5px 100px;'
      } else {
        return ''
      }
    }
  },

  /*
   *
   */
  components: {
    CustomPathwaysList
  }
}
</script>

<style>

</style>
