<template>
  <v-card color="green">
    <v-card-text class="white--text">
      <!-- Category -->
      <v-layout v-if="category" class="mb-1" row style="margin: 0">
        <v-flex xs6 class="bold">
          Category:
        </v-flex>

        <v-flex xs6>
          {{ category.label }}
        </v-flex>
      </v-layout>

      <!-- Difficulty -->
      <v-layout v-if="difficulty" class="mb-1" row style="margin: 0">
        <v-flex xs6 class="bold">
          Difficulty:
        </v-flex>

        <v-flex xs6>
          {{ difficulty }}
        </v-flex>
      </v-layout>

      <!-- Points -->
      <v-layout v-if="points" class="mb-1" row style="margin: 0">
        <v-flex xs6 class="bold">
          {{ (points > 1) ? 'Points' : 'Point' }}
        </v-flex>

        <v-flex xs6>
          {{ points }}
        </v-flex>
      </v-layout>

      <!-- Estimated Effort -->
      <v-layout v-if="estimate" row style="margin: 0">
        <v-flex xs6 class="bold">
          Estimated Effort:
        </v-flex>

        <v-flex xs6>
          {{ estimate }}
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['category', 'difficulty'],

  computed: {
    /*
     * Returns the estimated number of hour an exercise takes
     * based on its difficulty level
     */
    estimate () {
      if (!this.difficulty) {
        return null
      }

      if (this.difficulty === 'Concepts') {
        return '1 hour'
      }

      if (this.difficulty === 'Novice') {
        return '1 to 6 hours'
      }

      if (this.difficulty === 'Advanced Beginner') {
        return '6 to 12 hours'
      }

      if (this.difficulty === 'Competent') {
        return '1 to 3 days'
      }

      if (this.difficulty === 'Proficient') {
        return '3 to 7 days'
      }

      if (this.difficulty === 'Beyond Proficient') {
        return '1 to 6 weeks'
      }

      return null
    },

    /*
     * Returns the number of points an exercise is worth
     */
    points () {
      if (!this.difficulty) {
        return null
      }

      if (this.difficulty === 'Concepts') {
        return 0.5
      }

      if (this.difficulty === 'Novice') {
        return 1
      }

      if (this.difficulty === 'Advanced Beginner') {
        return 2
      }

      if (this.difficulty === 'Competent') {
        return 5
      }

      if (this.difficulty === 'Proficient') {
        return 8
      }

      if (this.difficulty === 'Beyond Proficient') {
        return 16
      }

      return null
    }
  }
}
</script>

<style>

</style>