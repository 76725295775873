
import { firestoreAction, vuexfireMutations } from 'vuexfire'

import Vuex from 'vuex'
import Vue from 'vue'
import firebase from 'firebase/app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    csrfToken: null,
    email: null,
    profile: null,
    roles: [],
    settings: {},
    uid: null,
  },

  getters: {
    //
    getEmail (state) {
      return state.email
    },

    //
    getOrgID (state) {
      if (typeof state.settings !== 'object') {
        return null
      }

      return state.settings.organisation
    },
    
    //
    getProfile (state) {
      return state.profile
    },

    //
    getCSRFToken (state) {
      return state.csrfToken
    },

    //
    getRoles (state) {
      return state.roles
    },

    //
    getSettings (state) {
      return state.settings
    },

    //
    getUID (state) {
      return state.uid
    },

    // returns TRUE if the user is an administration for the organization
    isAdministrator (state) {
      return state.settings
        && Array.isArray(state.settings.roles)
        && state.settings.roles.includes('organisation-administrator')
    }
  },

  actions: {
    // Bind 'settings' Doc
    bindSettings: firestoreAction(({ state, bindFirestoreRef }) => {
      return bindFirestoreRef('settings', firebase
        .firestore()
        .collection('settings')
        .doc(state.uid)
      )
    })
  },

  mutations: {
    /*
     * Retrieves the user's platform profile from Firestore
     */
    setProfile (state) {
      if (firebase.auth().currentUser) {
        // set the uid
        state.uid = firebase.auth().currentUser.uid
        
        // set the profile
        state.profile = firebase.firestore().collection('profiles')
          .doc(state.uid)
      }
    },

    //
    setEmail (state, email) {
      state.email = email
    },

    //
    setUid (state, uid) {
      state.uid = uid
    },

    //
    reset (state) {
      state.email = null
      state.uid = null
    },

    ...vuexfireMutations
  }
})
