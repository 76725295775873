<template>
  <v-card v-if="data" flat tile>
    <v-card-title class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
      Continued Professional Development
    </v-card-title>

    <v-divider />

    <v-card-text>
      <GChart
        type="LineChart"
        :data="chartData"
        :options="chartOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import { GChart } from 'vue-google-charts'

// View
export default {
  props: ['data'],

  data () {
    return {
      chartOptions: {
        chartArea: {
          width: '100%'
        },

        colors: ['#198f3d'],

        legend: {position: 'top'},
        title: 'The Last 18 Months',
        vAxis: {minValue: 0}
      }
    }
  },

  computed: {
    /*
     *
     */
    chartData () {
      var res = [['Month', 'Your score']]
      
      this.months.forEach(m => {
        var points = 0

        // validation
        if (!this.data) {
          return
        }

        if (this.data['monthly-scores'] && this.data['monthly-scores'][m] !== undefined) {
          points = this.data['monthly-scores'][m]
        }

        res.push([m, points])
      })

      return res
    },

    /*
     * Generate the months
     */
    months () {
      const months = []
      const dateStart = moment.tz(new Date(), 'Australia/Victoria')
      const dateEnd = moment.tz(new Date(), 'Australia/Victoria').subtract(18, 'month')

      while (dateStart.diff(dateEnd, 'months') >= 0) {
        months.push(dateEnd.format('MMM-YY').toLocaleLowerCase())
        dateEnd.add(1, 'month')
      }

      return months
    }

  },

  components: {
    GChart
  }
}
</script>

<style>

</style>