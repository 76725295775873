import user from '@/stores/user.js'

export default {
  computed: {
    /*
     *
     */
    getSettings () {
      return user.getters.getSettings
    },

    /*
     *
     */
    email () {
      return user.getters.getEmail
    },
    
    /*
     * Returns TRUE if the user is an organization administrators
     */
    isAdmin () {
      return user.getters.isAdministrator
    },

    /*
     *
     */
    orgID () {
      return user.getters.getSettings.organisation || null
    },

    /*
     *
     */
    roles () {
      return user.getters.getSettings.roles || []
    },

    /*
     *
     */
    uid () {
      return user.getters.getUID
    }
  }
}


