<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="headline">
          Invitation Form
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-form ref="form" v-model="form">
          <v-text-field v-model="email"
            :rules="(email.length > 0) ? [rules.present, rules.email] : [rules.present]"
            color="green"
            label="e-mail"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="loading"
          @click="dialog = true"
          class="white--text"
          color="green"
          small
          >
          
          Import List
        </v-btn>

        <v-spacer></v-spacer>
        
        <v-btn
          :disabled="!form || loading"
          :loading="loading"
          @click="inviteUser"
          class="white--text"
          color="green"
          small
          >
          
          Invite
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Invite Multiple Users
        </v-card-title>

        <v-card-text>
          <p class="fs-16">
            Please list one email address per line:
          </p>

          <v-textarea v-model="textarea"
            color="green"
            filled
            height="350"
            >
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <span v-if="emails.length > 0" style="font-size: 0.875rem">
            Emails: {{ emails.length || 0 }}
          </span>

          <v-spacer />

          <v-btn
            :disabled="emails.length === 0"
            :loading="loading"
            @click="inviteUsers"
            class="white--text"
            color="green"
            small
            >

            Invite
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase/app'

// mixins
import regex from '@/mixins/regex.js'
import ui from '@/mixins/ui.js'

// component
export default {
  mixins: [regex, ui],

  data () {
    return {
      dialog: false,
      email: '',
      emails: [],
      form: false,
      loading: false,
      textarea: null
    }
  },

  watch: {
    /*
     *
     */
    textarea (newVal) {
      if (newVal) {
        this.emails = this.textarea
          .split("\n")
          .filter(line => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/.test(line))
      } else {
        this.emails = []
      }
    }
  },

  methods: {
    /*
     * Invite a single user to the platform
     */
    inviteUser: async function () {
      this.emails = [this.email]

      this.inviteUsers()
    },

    /*
     * Invite multiple users to the platform
     */
    inviteUsers: async function () {
      var call = firebase.functions().httpsCallable('emailInviteOrganisationMembers')

      this.loading = true

      // prepare the HTTP payload
      var data = {
        emails: this.emails
      }

      // call API
      await call(data)
        .then(res => {
          this.throwSuccess(res)

          this.email = ''
          this.emails = []
          this.dialog = false
        })
        .catch(error => {
          this.throwError(error)
        })

      this.loading = false
    }
  }
}
</script>

<style>

</style>