<template>
  <div>
    <v-card flat tile>
      <v-card-title class="title grey lighten-3 pdb-10" style="font-weight: unset; margin-bottom: 0;">
        Courses &amp; Licenses
      </v-card-title>

      <v-tabs v-model="tab" color="green" slider-color="green" grow show-arrows>
        <v-tab href="#profile-courses">
          Courses
        </v-tab>

        <v-tab href="#profile-licenses" v-if="assignedLicenses.length">
          Licenses
        </v-tab>

        <v-tab href="#profile-custom-pathways" v-if="pathwaysLoaded">
          Custom Pathways
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" v-if="profileSettings" class="pd-10">
        <!-- The courses listed in the user's settings document -->
        <v-tab-item value="profile-courses" v-if="tab === 'profile-courses'">
          <v-alert type="success">
            The list below displays the courses the user has either purchased or been manually enrolled in. Please note that it does not include courses unlocked through licenses.
          </v-alert>

          <v-divider />

          <v-list dense>
            <v-list-item v-for="(course, i) in courses" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="formatText(course)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <!-- The licenses assigned to the user -->
        <v-tab-item value="profile-licenses" v-if="tab === 'profile-licenses'">
          <v-alert type="success">
            The list below displays the licenses assigned to the user. Licenses unlock many courses.
          </v-alert>

          <v-divider />

          <v-list dense>
            <v-list-item v-for="(license, i) in assignedLicenses" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="formatText(license.sku)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-tab-item>

        <!-- The custom pathways assigned to the user -->
        <v-tab-item value="profile-custom-pathways" v-if="tab === 'profile-custom-pathways' && pathwaysLoaded">
          <v-alert type="success">
            The list below shows the custom pathways assigned to the user. Check the boxes to add new pathways.
          </v-alert>

          <v-divider />

          <v-card :loading="loading" flat tile>
            <v-checkbox v-model="selected" v-for="(pathway, i) in orgPathways" :key="i"
              :disabled="disabled"
              :label="pathway['curriculum-title']"
              :value="pathway.id"
              color="green"
            ></v-checkbox>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase/app'

// mixins
import firestore from '@/mixins/firestore.js'
import organisation from '@/mixins/organisation.js'
import utils from '@/mixins/utils.js'

export default {
  props: ['profileSettings', 'userId'],

  mixins: [
    firestore,
    organisation,
    utils
  ],

  data () {
    return {
      disabled: false,
      selected: null,
      tab: null
    }
  },

  computed: {
    /*
     * Returns the licenses assigned to the user
     */
    assignedLicenses () {
      if (!Array.isArray(this.licenses)) {
        return []
      }

      return this.licenses.filter(l => l && l.uid === this.userId)
    },

    /*
     * Returns the pathways assigned to the user
     */
    assignedPathways () {
      if (!Array.isArray(this.orgPathways)) {
        return []
      }

      return this.orgPathways.filter(p => p && Array.isArray(p.users) && p.users.includes(this.userId))
        .map(p => p.id)
    },

    /*
     * Return the list of courses listed in the user's settings
     */
    courses () {
      if (!Array.isArray(this.profileSettings.bundles)) {
        return []
      }

      return this.profileSettings.bundles
    },

    /*
     * Returns TRUE if the view should show pathways
     */
    pathwaysLoaded () {
      return Array.isArray(this.selected)
        && Array.isArray(this.orgPathways)
        && this.orgPathways.length
    }
  },

  /*
   *
   */
  mounted () {
    if (this.assignedPathways.length && !this.selected) {
      this.selected = this.assignedPathways
    } else {
      this.selected = []
    }
  },

  watch: {
    /*
     * Sync Assigned Pathways with `selected`
     */
    assignedPathways (newVal) {
      this.selected = newVal
    },

    /*
     * Update the custom pathway `users` array in the backend
     */
    async selected (newVal, oldVal) {
      // validation: avoid race condition with mounted()
      if (!Array.isArray(oldVal) || !Array.isArray(newVal)) {
        return
      }

      //
      this.disabled = true

      // prepare payload object
      var payload = {}

      // prepare placeholder for documentId
      var pathwayId = null

      // detect removed item
      var removedItem = oldVal.filter(item => !newVal.includes(item))

      if (removedItem.length) {
        pathwayId = removedItem[0]

        payload['users'] = firebase.firestore.FieldValue.arrayRemove(this.userId)
      }

      // detect added item
      var addedItem = newVal.filter(item => !oldVal.includes(item))

      if (addedItem.length) {
        pathwayId = addedItem[0]

        payload['users'] = firebase.firestore.FieldValue.arrayUnion(this.userId)
      }

      // update firestore
      await this.fsUpdate('custom-pathways', pathwayId, payload)

      //
      this.disabled = false
    }
  }
}
</script>