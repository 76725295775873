<template>
  <div>
    <v-card v-if="show && !loading" class="member-card">    
      <!-- Managers -->
      <v-row>
        <v-col cols="8" md="4">
          <v-card-title>
            Managers:
          </v-card-title>

          <div v-if="loading">loading</div>

          <v-data-iterator
            :items="team['team-leaders']"
            hide-default-footer
            style="margin-top: 10px;"
            >
            <template v-slot:default="props">           
              <div v-for="(manager, index) in props.items" :key="index" class="pd-10">
                <!-- Manager Card -->
                <v-card flat style="margin: 2px 2px 2px 20px;">
                  <v-toolbar
                    color="grey lighten-3"
                    dense
                    flat
                    >
                    <v-card-subtitle>
                      {{ manager }}
                    </v-card-subtitle>

                    <v-spacer></v-spacer>

                    <v-btn icon @click="handleRemoveManager(manager)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-card>
              </div>
            </template>
          </v-data-iterator> 

          <div style="padding-left: 25px; margin-top: 10px;">
            <v-text-field v-if="textFieldVisible" v-model="selectedManager" 
              :rules="[rules.email]"
              dense 
              required 
              style="width: 250px;"
            ></v-text-field>

            <v-btn v-if="!textFieldVisible"
              @click="textFieldVisible = !textFieldVisible"
              color="green" 
              dark 
              depressed 
              right
              >

              add manager
            </v-btn>

            <v-btn v-if="textFieldVisible"
              @click="handleAddManager"
              color="green" 
              dark 
              depressed 
              >

              Confirm
            </v-btn>

            <v-btn v-if="textFieldVisible"
              @click="resetField"
              color="red" 
              dark 
              depressed 
              right
              style="margin-left:10px;"
              >

              Cancel
            </v-btn>

          </div>
        </v-col>

        <!-- Team Progress -->
        <v-col cols="12" md="8">
          <v-card-title>
            Team Progress:
          </v-card-title>

          <div class="progress" v-if="teamMembers.length > 0">
            <!-- Iterate over Team Members -->
            <v-data-iterator :items="teamMembers" hide-default-footer>
              <template v-slot:default="props">           
                <div v-for="(item, index) in props.items" :key="index">
                  <TeamMemberCard :data="item" :search="search" :removeUser="removeUser" />
                  <br>
                </div>
              </template>
            </v-data-iterator> 
          </div>

          <!-- Alert if no team members exist -->
          <template v-else>
            <v-alert 
              style="margin-top:10px;"
              type="info" 
              transition="scale-transition"
              >
              
              No members in team
            </v-alert>
          </template>
        </v-col>
      </v-row>
    </v-card>

    <!-- Progress Bar -->
    <v-progress-linear v-else color="green" indeterminate></v-progress-linear>
  </div>
</template>

<script>
// Firebase
import firebase from 'firebase'

// Mixins
import organisation from '@/mixins/organisation.js'
import snack from '@/stores/ui.js'
import user from '@/mixins/user.js'
import validation from '@/mixins/validation.js'

// Components
import ManagerCard from '@/components/cards/ManagerCard'
import RemoveAlert from '@/components/ui/alerts/RemoveAlert'
import TeamMemberCard from '@/components/cards/TeamMemberCard'

// View
export default {
  props: ['team'],

  mixins: [organisation, user, validation],

  data () {
    return {
      loading: false,
      search: [],
      selectedManager: "",
      settingsDocsLoaded: true,
      textFieldVisible: false,
      valid: false
    }
  },

  methods: {
    /*
     *
     */
    async removeUser (user) {
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .doc(this.$route.params.id)
        .update({
          members: firebase.firestore.FieldValue.arrayRemove(user)
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', "Team Member(s) Removed from Team")
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     *
     */
    handleAddManager () {
      var itemDomain = this.selectedManager.split('@')[1]
      if (/.+@.+\..+/.test(this.selectedManager) && itemDomain === this.domain) {
        this.addLeader(this.selectedManager)
        this.resetField()
      } else {
        snack.commit('triggerErrorSnackbar', "Email is incorrect or the user is not part of this organisation")
        this.selectedManager = ""
      }
    },

    /*
     *
     */
    handleRemoveManager (manager) {
      this.selectedManager = manager

      this.removeLeader(manager)
    },

    /*
     *
     */
    async removeLeader (member) {
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .doc(this.$route.params.id)
        .update({
          'team-leaders': firebase.firestore.FieldValue.arrayRemove(member)
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', `${member} is no longer a Team Leader`)
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     *
     */
    async addLeader (member) {
      await firebase.firestore()
        .collection('organisations')
        .doc(this.orgID)
        .collection('teams')
        .doc(this.$route.params.id)
        .update({
          'team-leaders': firebase.firestore.FieldValue.arrayUnion(member)
        })
        .then(() => {
          snack.commit('triggerSuccessSnackbar', `${member} Added as Team Leader`)
        })
        .catch(error => {
          snack.commit('triggerErrorSnackbar', error)
        })
    },

    /*
     *
     */
    resetField () {
      this.selectedManager = ""
      this.textFieldVisible = false
    }
  },

  computed: {
    /*
     *
     */
    show () {
      return ((this.team.members[0] || this.team['team-leaders'][0]) && this.settingsDocs[0])
    },

    /*
     *
     */
    teamMembers () {
      var array = []
      this.loading =true

      if (this.team.members && this.settingsDocs) {
        this.settingsDocs.forEach(doc => {
          if (this.team.members.includes(doc.email)) {
            array.push(doc)
          }
        })
      }

      this.loading = false

      return array
    }
  },

  components: {
    ManagerCard,
    RemoveAlert,
    TeamMemberCard
  }
}
</script>

<style>

</style>