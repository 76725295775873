<template>
  <div>
    <!-- Progress Bar -->
    <v-progress-linear v-if="!loaded" color="green" indeterminate></v-progress-linear> 

    <!-- Analytics -->
    <v-card v-else>
      <v-card-title class="green">
        <div class="headline white--text">
          Users Analytics
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class="pb-20 pt-20">
        <v-layout>
          <v-flex v-for="(item, index) in items" :key="index">
            <div class="analytics-value">
              {{ item.value }}
            </div>

            <div class="analytics-title">
              {{ item.label }}
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// mixins
import organisation from '@/mixins/organisation.js'

//
export default {
  mixins: [organisation],

  computed: {
    /*
     *
     */
    active () {
      return this.scores.filter(s => s.totalPoints && s.totalPoints > 0).length
    },

    /*
     *
     */
    loaded () {
      return Array.isArray(this.settingsDocs) && this.settingsDocs.length > 0
    },

    /*
     *
     */
    items () {
      return [
        {
          label: 'Registered Users',
          value: this.settingsDocs.length
        },

        {
          label: 'Active Users',
          value: this.active
        }
      ]
    }
  }
}
</script>

<style>

</style>