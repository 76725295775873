import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu: true,
    alert: false,
    persistentSnackBar: false,
    persistentSnackBarText: null,
    snackbar: false,
    snackbarText: null,
    snackbarColor: '',
    snackbarTimeout: 4000
  },

  getters: {
    menu: state => {
      return state.menu
    },

    alert: state => {
      return state.alert
    },

    persistentSnackBarText: state => {
      return state.persistentSnackBarText
    },

    snackbarStatus: state => {
      return state.snackbar
    },

    snackbarText: state => {
      return state.snackbarText
    },

    snackbarColor: state => {
      return state.snackbarColor
    },

    snackbarTimeout: state => {
      return state.snackbarTimeout
    }
  },

  mutations: {
    toggleMenu (state) {
      state.menu = !state.menu
    },

    toggleAlert (state) {
      state.alert = !state.alert
    },
    
    closePersistentSnackbar (state) {
      state.persistentSnackBar = false
      state.persistentSnackBarText = null
    },

    closeSnackbar (state) {
      state.snackbar = false
    },

    triggerPersistentSnackbar (state, text) {
      state.persistentSnackBar = true
      state.persistentSnackBarText = text
    },

    triggerSuccessSnackbar (state, text) {
      state.snackbar = true
      state.snackbarColor = 'green'

      if (text['data'] && text['data']['message']) {
        state.snackbarText = text['data']['message']
      } else if (text['data'] && typeof text['data'] === 'string') {
        state.snackbarText = text['data']
      } else {
        state.snackbarText = text
      }

      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    },

    triggerErrorSnackbar (state, error) {
      state.snackbar = true
      state.snackbarColor = 'error'
      state.snackbarText = error['message'] || error
      
      setTimeout(() => { state.snackbar = false }, state.snackbarTimeout)
    }
  }
})