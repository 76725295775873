<template>
  <v-container>
    <v-card width="1200px">
      <!-- Toolbar -->
      <v-toolbar flat dark dense color="green">
        <v-list-item-icon>
          <v-icon>mdi-book-edit</v-icon>
        </v-list-item-icon>

        <v-toolbar-title>
          Exercise Builder
        </v-toolbar-title>
      </v-toolbar>

      <!-- Loading Bar -->
      <v-card-text v-if="!loaded">
        <v-progress-linear
          color="green"
          height="5"
          indeterminate
          style="margin: 5px 0px;"
        ></v-progress-linear>
      </v-card-text>

      <!-- Form -->
      <v-card-text v-if="loaded">
        <v-form ref="form" v-model="form">
          <!-- Title -->
          <v-text-field v-model="title"
            :rules="(title.length > 0) ? [rules.present] : [rules.present]"
            color="green"
            filled
            label="Title"
            required
          ></v-text-field>

          <!-- Difficulty and Category -->
          <v-layout row wrap style="margin: 0">
            <v-flex xs12 sm6>
              <v-select v-model="category" 
                :items="categories"
                color="green"
                filled
                item-text="label"
                label="Category"
                return-object
                style="padding-right: 3px;"
              ></v-select>
            </v-flex>

            <v-flex xs12 sm6>
              <v-select v-model="difficulty" 
                :items="difficulties"
                color="green"
                filled
                label="Difficulty Level"
                return-object
                style="padding-left: 3px;"
              ></v-select>
            </v-flex>
          </v-layout>

          <!-- Description -->
          <v-divider class="mb-2 mt-2" />
          
          <b>Description:</b> <span style="color: red;">*</span>

          <v-textarea v-model="description"
            :rules="(description.length > 0) ? [rules.present] : [rules.present]"
            class="mt-1"
            color="green"
            filled
            height="150"
            required
            >
          </v-textarea>

          <b>Learning Objectives:</b> (Optional)

          <v-textarea v-model="objectives"
            class="mt-1"
            color="green"
            filled
            height="150"
            >
          </v-textarea>

          <b>Mission Objectives:</b> (Optional, one objective per line)

          <v-textarea v-model="mission"
            class="mt-1"
            color="green"
            filled
            height="150"
            >
          </v-textarea>

          <b>Key Questions:</b> (Optional, one per line)

          <v-textarea v-model="questions"
            class="mt-1"
            color="green"
            filled
            height="150"
            >
          </v-textarea>

          <!-- Specifications -->
          <v-divider class="mb-2 mt-2" />

          <b>Specifications:</b> <span style="color: red;">*</span>

          <SpecificationsBuilder v-model="specifications" />

          <!-- References -->

          <v-divider class="mb-2 mt-2" />

          <b>References:</b>

          <ReferencesBuilder v-model="references" class="mt-3" />
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Preview -->
    <Preview v-if="loaded"
      :category="category"
      :description="description"
      :difficulty="difficulty"
      :mission="mission"
      :objectives="objectives"
      :questions="questions"
      :references="references"
      :specifications="specifications"
      :title="title"
    ></Preview>
  </v-container>
</template>

<script>
// mixins
import firestore from '@/mixins/firestore.js'
import platform from '@/mixins/platform.js'
import regex from '@/mixins/regex.js'

// components
import ReferencesBuilder from '@/components/cards/exercise-builder/ReferencesBuilder'
import SpecificationsBuilder from '@/components/cards/exercise-builder/SpecificationsBuilder'
import Preview from '@/components/cards/exercise-builder/Preview'

// view
export default {
  mixins: [firestore, platform, regex],

  data () {
    return {
      category: null,
      description: '',
      difficulty: null,
      form: null,
      loaded: false,
      mission: '',
      objectives: '',
      questions: '',
      references: [],
      specifications: [],
      title: ''
    }
  },

  computed: {
    /*
     * Return the exercise ID that is being edit.
     */
    docId () {
      return this.$route.params.docId || null
    }
  },

  async mounted () {
    /*
     * Load exercise to be edited
     */
    if (this.docId) {
      var data = await this.fsGet('exercises', this.docId)

      if (data) {
        // initial object copy
        this.safeCopyObjects(data, this.$data)

        // retrieving a proper category object
        this.category = this.categories.filter(c => c.uid === this.category)[0]

        // fixing fields
        if (Array.isArray(data.mission)) {
          this.mission = data.mission.join("\n")
        }

        if (Array.isArray(data.questions)) {
          this.questions = data.questions.join("\n")
        }
      }
    }

    this.loaded = true
  },

  components: {
    ReferencesBuilder,
    SpecificationsBuilder,
    Preview
  }
}
</script>

<style>

</style>