export default {
  methods: {
    /*
     *
     */
    formatText: function (text) {
      if (text.toLowerCase() === 'grc') {
        return 'GRC'
      }
      
      return text
        .split('-').join(' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    },

    /*
     * Safely copies the keys from one object into another object
     * only if the key of the first object has the key of the second
     * object 
     */
    safeCopyObjects (from, to) {
      Object.keys(from).forEach(k => {
        if (Object.keys(to).includes(k)) {
          to[k] = from[k]
        }
      })
    }
  }
}