// stores
import organisation from '@/stores/organisation.js'

// mixins
import user from '@/mixins/user.js'

// mixin
export default {
  mixins: [user],

  computed: {
    /*
     * Retrieve the organization's licenses
     */
    licenses () {
      return organisation.getters.getLicenses
    },

    /*
     * Returns the organization document
     */
    orgDoc () {
      return organisation.getters.getOrgDoc
    },
    
    /*
     * Returns an array with the email addresses of all users in the organization
     */
    orgEmails () {
      if (this.settingsDocs) {
        return this.settingsDocs.map(settings => settings.email)
      }

      return []
    },

    /*
     * Retrieve the organization's pathways
     */
    orgPathways () {
      return organisation.getters.getPathways
    },

    /*
     * Retrieve the list of teams in an organization
     */
    orgTeams () {
      return organisation.getters.getOrgTeams
    },

    /*
     * Retrieves the permissions of the organization
     */
    permissions () {
      // validation
      if (!this.orgDoc || !Array.isArray(this.orgDoc.permissions)) {
        return []
      }

      return this.orgDoc.permissions
    },

    /*
     * Retrieve the reports that an organization has generated
     */
    reports () {
      return organisation.getters.getReports
    },

    /*
     * Retrieve the scores of all the users in the organisation
     */
    scores () {
      return organisation.getters.getScores
    },

    /*
     * Retrieve the settings documents of all users in the organisation
     */
    settingsDocs () {
      return organisation.getters.getSettingsDocs
    },

    /*
     * Retrieve the submissions from users part of the organisation
     */
    submissions () {
      return organisation.getters.getSubmissions
    }
  },

  methods: {
    /*
     * Returns TRUE if an organization has the specified permission
     */
    hasPermission (permission) {
      return this.permissions.includes(permission)
    },

    /*
     * Retrieve a user's email address based on their UID
     */
    getEmailAddress (uid) {
      var res = this.settingsDocs.filter(doc => doc.id === uid)

      if (res.length) {
        return res[0].email
      } else {
        window.console.error('Error retrieving email for uid=' + uid)

        return 'unknown'
      }
    },

    /*
     * Returns the settings document of a user by email
     */
    getUserDocByEmail (email) {
      var res = this.settingsDocs.filter(doc => doc.email === email)

      if (!res.length) {
        return null
      }

      return res[0]
    },

    /*
     * Returns a given user's UID
     */
    getUserUID (email) {
      var user = this.getUserDocByEmail(email)

      if (!user || !Array.isArray(user.roles)) {
        return null
      }

      return user.id
    },

    /*
     * Returns TRUE if the specified user is admin
     */
    isUserAdmin (email) {
      var user = this.getUserDocByEmail(email)

      if (!user || !Array.isArray(user.roles)) {
        return false
      }
      
      return user.roles.includes('organisation-administrator')
    }
  }
}